import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Auth } from './components/auth';
import AuthSuccess from './components/authsucc';
import Dashboard from './components/dashboard';
import { auth } from "./config/firebase";
import AddProperty from './components/addproperty';
import Area from './components/area';
import CustomerDashBoard from './components/customerdashboard';
import Home from './components/home';
import Landing from './components/landing'
import FAQ from './components/FAQ';
import Details from './components/Details';
import { Auth2 } from './components/auth2';
import Rewardcustomerdashboard from './components/rewardcustomerdashboard';
import RewardDetailsCard from './components/rewarddetails';
import RedeemCard from './redeem';
import RedeemSuccessCard from './redeemsuccesscard';
import DetailsShare from './components/OakBrookGarden';
import Search from './components/search';
import { DynamicRoute } from './components/DynamicRoute';
import Footer from './components/Footer';

function App() {
  const [user, setUser] = useState(null);
  const selectedProject = localStorage.getItem("selectedProject");
  const currentUrl = window.location.origin;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    // cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <>
    
    <Router>
      <Routes>
      <Route path='/' element={<Home/>}/>
        <Route path="/auth" element={<Auth />} />
        <Route path='/rewardauth' element={ <Auth2/>}/>
        {/* <Route path="/auth-success" element={<AuthSuccess />} /> */}
        <Route path="/addproperty" element={<AddProperty />} />
        <Route path="/dashboard" element={user ? <Dashboard user={user} /> : null} />
        {/* <Route path='/area' element={<Area/>}/> */}
        {/* <Route path='/customerdash' element={user ? <CustomerDashBoard User={user} /> : null}/> */}
        <Route path='/rewardcustomerdash' element={user ? <Rewardcustomerdashboard User={user}  /> :null}/>
        {/* <Route path="/:propertyName" element={<Details />} /> */}
        <Route path="/:sanitizedProjectName" element={<DynamicRoute/>} />
        {/* <Route path="/redeem" element={<RedeemCard/>}/> */}
        <Route path="/redeem-success" element={<RedeemSuccessCard/>}/>
        {/* <Route path='/home' element={<Home/>}/>
        <Route path='/landing' element={<Landing/>}/> */}
        <Route path='/faq' element={<FAQ/>}/>
         <Route path='/rewarddetails' element={<RewardDetailsCard/>}/> 
         <Route path='/search' element={<Search/>}/>
      </Routes>
    </Router>
    <Footer/>
    </>
  );
}

export default App;
