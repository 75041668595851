/* import React from "react";
// import "../index.css";
import bannerImage from "../images/thirdeye.png"; // Add your image here
import "../components/home.css"
import { HeaderContent } from "./Faq.style";
import { Header } from "./dashboard";
import logo from "../images/thirdeye.png";
import { Logo } from "./area";
import sample from "../images/sampleimg.jpg"
import cmg from "../images/commingsoon.jpg"




const listings = [
  {
    id: 1,
    title: 'Pharma City',
    location: 'Yacharam, Telangana',
    price: 'Own A Piece Of Land At ₹100 ',
    status: 'Coming Soon',
    image: cmg,
    link: '/',
  },
  {
    id: 2,
    title: 'Oak Brook Gardens',
    location:' Pochampally Road, Ramoji Film City, Telangana',
    price: 'You Can Buy  1 SQYD Too',
    status: 'on sale',
    image: sample,
    link: '/details',
  },
  {
    id: 3,
    title: 'Dry Port',
    location: 'Hyderabad, Telangana',
    price: 'You Can Buy  1 SQYD Too',
    status: 'Coming Soon',
    image:  cmg,
    link: '/',
  },
];
function Home() {
  return (<>
    <Header>
          <HeaderContent>
            <Logo src={logo} alt="Thirdeye" />
          </HeaderContent>
        </Header>
        <div className="container">
      <div className="property-grid">
        {listings.map((property) => (
          <div key={property.id} className="property-tile">
            <img src={property.image} alt={property.title} className="property-image" />
            <div className="property-info">
              <h3 className="property-title">{property.title}</h3>
              <p className="property-location">{property.location}</p>
              <p className="property-price"> {property.price}</p>
              <span className={`property-status ${property.status.toLowerCase()}`}>
                {property.status}
              </span>
              {property.status.toLowerCase() !== "coming soon" && (
  <a href={property.link} className="view-details-btn">View Details</a>)}
      <div className="flex items-center justify-center h-full">
  <a 
    href={property.link} 
    className="text-blue-500 hover:text-blue-700  font-semibold transition-all duration-300 mt-5"
  >
   Share & Earn Land!
  </a>
</div>

            </div>
          
          </div>
        ))}
      </div>
    </div>
</>
  );
}

export default Home;
 */










import React, { useEffect, useState } from "react";
import "../components/home.css";
import { HeaderContent } from "./Faq.style";
import { Header } from "./dashboard";
import logo from "../images/thirdeye.png";
import { Logo } from "./area";
import sample from "../images/sampleimg.jpg";
import sample2 from "../images/THCard.jpg"
import cmg from "../images/commingsoon.jpg";
import { useNavigate } from "react-router-dom";



import CampaignIcon from '@mui/icons-material/Campaign';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import PreviewIcon from '@mui/icons-material/Preview';
import { FaRupeeSign } from "react-icons/fa";
import { auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import Footer from "./Footer";
// Dynamically generate unique IDs for each property
/* const listingsWithIds = listings.map((property) => ({
  id:  crypto.randomUUID(), 
  ...property,
})); */


function Home() {
  const navigate=useNavigate()

  const [c1open ,  setC1Open] = useState(false)
  const [c2open ,  setC2Open] = useState(false)
  const [c3open ,  setC3Open] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true); 
      } else {
        setIsLoggedIn(false); 
      }
    });

    return () => unsubscribe();
  }, []);
  
  // Property listings without hardcoded IDs
const listings = [
  {
    id:"0123456789",
    title: "Pharma City",
    location: "Yacharam, Telangana",
    price: "Own A Piece Of Land At ₹100 ",
    status: "Coming Soon",
    image: cmg,
    link: "/",
  },
  {
    id:"xVBe90BdInon8rxSb1nF",
    title: "Oak Brook Gardens",
    location: "Pochampally Road, Ramoji Film City, Telangana",
    price: "You Can Buy 1 SQYD Too",
    status: "on sale",
    image: sample,
    link: "/details",
  },
  {
    id:"qfhjwm9Akdz1j6s1VbSi",
    title: "Tirumala Hills 2",
    location: "Kawadipally, Abdullapurmet,Hyderabad, Telangana",
    price: "The God’s Own Residence",
    status: "on sale",
    image: sample2,
    link: "/details",
  },
];

const handleopenprofile =(property)=>{



 // Replace spaces with hyphens in the property title
 const formattedTitle = property.title.replace(/\s+/g, '-');

 // Save the property ID in local storage
 localStorage.setItem('selectedProject', property.id);

 console.log("checking in home propertyId", localStorage.getItem("selectedProject"))

 // Navigate to the new URL
 navigate(`/${formattedTitle}`);

}


  return (
    <>
      <Header>
        <HeaderContent>
          <Logo src={logo} alt="Thirdeye" />
          {isLoggedIn && (<h3
            className="flex items-center gap-2 text-blue-500 text-xl font-bold cursor-pointer hover:text-blue-700 hover:scale-105 transition-all duration-300 mr-2"
            onClick={() => {
              navigate("/rewardcustomerdash");
            }}
          >
            Dashboard
          </h3>)}
        </HeaderContent>
      </Header>
      <div className="container">
        <div className="property-grid">
          {listings.map((property) => (
            <div key={property.id} className="property-tile">
              <img
                src={property.image}
                alt={property.title}
                className="property-image"
              />
              <div className="property-info">
                <h3 className="property-title">{property.title}</h3>
                <p className="property-location">{property.location}</p>
                <p className="property-price">{property.price}</p>
                <span
                  className={`property-status ${property.status.toLowerCase()}`}
                >
                  {property.status}
                </span>
                {property.status.toLowerCase() !== "coming soon" && (
                  <a className="view-details-btn"  onClick={()=>handleopenprofile(property)} style={{cursor:"pointer"}}>
                    View Details
                  </a>
                )}
                <div className="flex items-center justify-center h-full">
                  <a
                     onClick={property.status !== "Coming Soon" ? () => handleopenprofile(property) : undefined}
                    className={`${property.status === "Coming Soon" ? `text-blue-500  font-semibold transition-all duration-300 mt-5  flex  items-center justify-center content-center` : `text-blue-500 hover:text-blue-700 font-semibold transition-all duration-300 mt-5 cursor-pointer flex  items-center justify-center content-center`}`}

                  >
                   <FaRupeeSign/> Share & Earn Land!
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="container-tab">
          <ul  className=" ulclass"onClick={()=> setC1Open(!c1open)} >  
         
          <CampaignIcon></CampaignIcon>
          Promote My Project {c1open?"-":"+"}
           { c1open && (<><li className="liclass"> Free Advertisement</li>
            <li className="liclass">Faster Promotion Using LAND Rewards</li>
            <li className="liclass" onClick={()=> navigate("/auth")}>Get Started</li>
            </>)}
          </ul>

          <ul   className=" ulclass"onClick={()=> setC2Open(!c2open)} > <TravelExploreIcon></TravelExploreIcon> Find Properties {c2open?"-":"+"}
           {c2open && (<><li className="liclass" onClick={()=> navigate('/search')}> Easy Search          </li>
            <li className="liclass">Fractional Purchase as low as 1SQYD</li>
            <li className="liclass"  onClick={()=> navigate("/auth")}>Get Started</li></>)}
          </ul>
          <ul   className=" ulclass"onClick={()=> setC3Open(!c3open)} > <PreviewIcon></PreviewIcon> Monitor My Land {c3open?"-":"+"}
           { c3open && (<><li className="liclass">Keep An Eye on Your Existing Open Land </li>
            <li className="liclass">Get Regular Updates in Your Mobile</li>
            <li className="liclass" onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.saralam.realblock&pcampaignid=web_share")}>Get  Mobile App</li>
            </> )}
          </ul>
        </div>
      </div>
    
    </>
  );
}

export default Home;
