import React, { useEffect, useState } from "react";
import "./redeemcard.css";
import { addDoc, collection, getDocs, getFirestore } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./config/firebase";
import { useNavigate } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";

const RedeemCard = ({setRedeemCardOpen}) => {
  
  const db = getFirestore();
  const totalLands=localStorage.getItem("totalLands")
  const [totalLandsLocal,setTotalLandsLocal]=useState(Number(totalLands))
  const [shopItemList,setShopItemList]=useState([])
  const [isFormOpen,setIsFormOpen]=useState(null)
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedCard,setSelectedCard]=useState(null)
  const navigate=useNavigate()

  useEffect(() => {
    // Listen for the authenticated user
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        
      } else {
        setCurrentUser(null);
       
      }
    });

    return () => unsubscribe();
  }, []);
  const fetchShopItems = async () => {
    try {
      const shopItemRef = collection(db, "shopItem"); // Reference to the "shopItem" collection
      const querySnapshot = await getDocs(shopItemRef); // Fetch all documents

      // Map the documents into an array of data
      const items = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Include document ID
        ...doc.data(), // Include document data
      }));

      const filterList=items.filter((ele)=>Number(ele.LANDs)<=totalLandsLocal)


      setShopItemList(filterList); // Update state with the fetched data
    } catch (error) {
      console.error("Error fetching shop items:", error);
    }
  };
  useEffect(() => {
    fetchShopItems();
  }, [totalLandsLocal])


  const handleFormOpen=(item)=>{
       
       setIsFormOpen(item?.id)
       setSelectedCard(item)
  }

  // Form state for controlled inputs
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    city: "",
    state: "",
    pincode: "",
    address: "",
  });

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleFormSubmit = async (e,item) => {
    e.preventDefault();

    try {
      if(Number(item.LANDs)>Number(totalLands)){
        if (window.confirm("Not Enough LAND to Redeem!! Earn more. Go to the home page")) {
          window.location.href = window.location.origin;
      }
        return
      }
      const redeemOrderRef = collection(db, "redeemOrder");
      const landRewardsRef = collection(db, "landRewards");
      await addDoc(redeemOrderRef, {
        LANDs: item.LANDs || "", 
        address: formData.address,
        city: formData.city,
        deliveredAt: "", // Current timestamp
        itemId: item.id || "", // Assuming `item` has an `id` field
        name: formData.name,
        orderedAt: new Date(), 
        postalCode: formData.pincode,
        userId: currentUser?.uid || "", // Assuming `currentUser` contains `uid`
      });

      await addDoc(landRewardsRef, {
        LANDs: -(Number(item.LANDs || 0)),
        activity:"REDEEM",
        createdAt: new Date(), // Current timestamp
        projectId: "", // Project ID
        referralLink:"", // Referral link
        referredUserId: "",
        userId: currentUser?.uid || "", // User ID
      });

       




      alert("Redeem order created successfully!");
      setFormData({
        name: "",
        mobile: "",
        email: "",
        city: "",
        state: "",
        pincode: "",
        address: "",
      })
      setIsFormOpen(null); // Close the form
      navigate("/redeem-success")
      
    } catch (error) {
      console.error("Error creating redeem order:", error);
      alert("Failed to create redeem order. Please try again.");
    }
  };

  
  
  


  return (
    <div>
      <a onClick={()=>setRedeemCardOpen(false)} className="redeem-go-back" >Go Back</a>
    <div className="redeem_container">
      <h2 className="redeem_title">Redeem Your LANDs</h2>
      <p className="redeem_subtitle">Select an Item for {Number(totalLands)} LANDs</p>

      {/* <div className="redeem_points">
        <span className="redeem_points-label">LANDs</span>
        <span className="redeem_points-value">{totalLandsLocal}</span>
        <div className="redeem_arrow-buttons">
          <button className="redeem_arrow up" onClick={()=>setTotalLandsLocal(prev=>prev+1)}>▲</button>
          <button className="redeem_arrow down" onClick={()=>setTotalLandsLocal(prev=>prev-1)}>▼</button>
        </div>
      </div> */}

<div className="redeem-points">
  <span className="label">LANDs</span>
  <div className="controls">
    {/* <button
      className="btn decrement"
      onClick={() => setTotalLandsLocal((prev) => Math.max(prev - 1, 0))}
    >
      -
    </button> */}
    <input
    style={{
      width:'80px'
    }}
      className="value-input"
      type="number"
      value={totalLandsLocal}
      onChange={(e) => {
        const newValue = parseInt(e.target.value, 10);
        setTotalLandsLocal(isNaN(newValue) ? 0 : newValue);
      }}
    />
    {/* <button
      className="btn increment"
      onClick={() => setTotalLandsLocal((prev) => prev + 1)}
    >
      +
    </button> */}
  </div>
</div>

      <div className="redeem_card-container">
         {shopItemList.map(
          (item, index) => (
            <>
            <div onClick={()=>handleFormOpen(item)}
              className={`redeem_card ${isFormOpen === item?.id ? "selected" : ""}`}
              key={item?.id}
              
            >
              
                

              <div className="item-redeem-name">{item?.itemName}</div>
              <div className="lands-inr">
              <div className="lands-text">LAND {item?.LANDs}</div>
              <div className="inr-text">
                <FaRupeeSign/>{item?.inrValue}
              </div>
              </div>
            </div>


</>
            
            
            
          )
        )} 
        


      </div>


      <div>
    {isFormOpen===selectedCard?.id && (<form className="redeem_form" onSubmit={(e)=>handleFormSubmit(e,selectedCard)}>
    {/* <div className="redeem_form-row">
      <input type="text" placeholder="Name" className="redeem_input" />
      <input type="text" placeholder="Mobile" className="redeem_input" />
      <input type="email" placeholder="Email" className="redeem_input" />
    </div>
    <div className="redeem_form-row">
      <input type="text" placeholder="City" className="redeem_input" />
      <input type="text" placeholder="State" className="redeem_input" />
      <input type="text" placeholder="Pincode" className="redeem_input" />
    </div>
    <div className="redeem_form-row">
      <input
        type="text"
        placeholder="Address"
        className="redeem_input address"
      />
    </div> */}
  
  <div className="redeem_form-row">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
              className="redeem_input"
              required // Makes this input mandatory
            />
            <input
              type="text"
              name="mobile"
              placeholder="Mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              className="redeem_input"
              required // Makes this input mandatory
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              className="redeem_input"
              required // Makes this input mandatory
            />
          </div>
          <div className="redeem_form-row">
            <input
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleInputChange}
              className="redeem_input"
              required // Makes this input mandatory
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              value={formData.state}
              onChange={handleInputChange}
              className="redeem_input"
              required // Makes this input mandatory
            />
            <input
              type="text"
              name="pincode"
              placeholder="Pincode"
              value={formData.pincode}
              onChange={handleInputChange}
              className="redeem_input"
              required // Makes this input mandatory
            />
          </div>
          <div className="redeem_form-row">
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleInputChange}
              className="redeem_input address"
              required // Makes this input mandatory
            />
          </div>
    <div className="redeem_form-buttons">
      <button type="submit" className="redeem_button proceed">
        Proceed
      </button>
      <button type="button" className="redeem_button cancel" onClick={()=>setIsFormOpen(null)}>
        Cancel
      </button>
    </div>
  </form>)}
  </div>
    </div>
    </div>
    
  
  );
};

export default RedeemCard;
