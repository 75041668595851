import React, { useEffect, useState } from 'react';
import './redeemsuccesscard.css';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebase';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';

const RedeemSuccessCard = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [totalLands, setTotalLands] = useState(0);
    const db = getFirestore();
    useEffect(() => {
        // Listen for the authenticated user
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setCurrentUser(user);
            fetchLandRewards(user.uid);
          } else {
            setCurrentUser(null);
            
          }
        });
    
        return () => unsubscribe();
      }, []);

    const fetchLandRewards = async (userId) => {
        try {
          const q = query(collection(db, "landRewards"), where("userId", "==", userId));
          const querySnapshot = await getDocs(q);
      
          const rewards = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
      
          const landsSum = rewards.reduce((sum, reward) => sum + (reward.LANDs || 0), 0);
      
          
          
          setTotalLands(landsSum);
        } catch (error) {
          console.error("Error fetching land rewards:", error);
        }
      };
    const navigate=useNavigate()
  return (
    <div className="success_card-container">
      <div className="success_card-box">
        <h1 className="success_card-title">Redeem Your LANDs</h1>
        <p className="success_card-message">
          Congratulations!! You’ve successfully redeemed.
          <br />
          You have <span className="success_card-count">{totalLands} LANDs</span> left.
        </p>
        <button className="success_card-button" onClick={() =>navigate("/rewardcustomerdash") }>
          Home
        </button>
      </div>
    </div>
  );
};

export default RedeemSuccessCard;
