import React, { useEffect, useState } from "react";
import "./Details.css";
import { Header } from "./dashboard";
import logo from "../images/thirdeye.png";
import { Logo } from "./area";
import { HeaderContent } from "./Faq.style";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FaRupeeSign, FaTachometerAlt, FaTimes, FaTimesCircle, FaWindowClose } from "react-icons/fa";

import img01 from "../images/oak1.png";
import img02 from "../images/oak2.png";
import img03 from "../images/oak3.jpg";
import img04 from "../images/oak4.jpg";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Auth2 } from "./auth2";
import { Auth } from "./auth";
import AuthSuccess from "./authsucc";
import AlertComponent from "./alertcomponent";
import { auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import sample from "../images/sampleimg.jpg";
import cmg from "../images/commingsoon.jpg";
import { addDoc, collection, getDocs, getFirestore, orderBy, query, updateDoc, where } from "firebase/firestore";

const OakBrookGarden = () => {

  //static project list

  const listings = [
    {
      id:"0123456789",
      title: "Pharma City",
      location: "Yacharam, Telangana",
      price: "Own A Piece Of Land At ₹100 ",
      status: "Coming Soon",
      image: cmg,
      link: "/",
    },
    {
      id:"xVBe90BdInon8rxSb1nF",
      title: "Oak Brook Gardens",
      location: "Pochampally Road, Ramoji Film City, Telangana",
      price: "You Can Buy 1 SQYD Too",
      status: "on sale",
      image: sample,
      link: "/details",
    },
    {
      id:"7890123456",
      title: "Dry Port",
      location: "Hyderabad, Telangana",
      price: "You Can Buy 1 SQYD Too",
      status: "Coming Soon",
      image: cmg,
      link: "/",
    },
  ];

  // List of images for the carousel
  const images = [img01, img02, img03, img04];

  const [authFormOpen, setAuthFormOpen] = useState(false);
  const navigate = useNavigate();

  const [rewardFormOpen, setRewardFormOpen] = useState(false);
  const [authNewUser, setauthNewUser] = useState(false);
  const [alertFormOpen, setAlertFormOpen] = useState(false);
  const db = getFirestore();

  const [rewardAuthNewUser, setRewardAuthNewUser] = useState(false);
  const [rewardAlertFormOpen, setRewardAlertFormOpen] = useState(false);
  const [propertyDataAvailable,setPropertyDataAvailable]=useState(null);

  const selectedProject = localStorage.getItem("selectedProject") || null;
  const senderUserId = localStorage.getItem("senderUserId") || null;
  const trackProject = localStorage.getItem("trackProject") || null;

  const { sanitizedProjectName } = useParams(); // Fetch dynamic segment
  const { search } = useLocation(); // Fetch query parameters
  const decodedTitle = sanitizedProjectName.replace(/-/g, ' ');
  const [user,setUser]=useState(null)

  const queryParams = new URLSearchParams(search);
  const propId = queryParams.get("propId");
  const userId = queryParams.get("userId");

  

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
        setIsLoggedIn(true); 
      } else {
        setIsLoggedIn(false); 
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    localStorage.setItem("senderUserId", userId);
    /* localStorage.setItem("selectedProject", propId); */
    localStorage.setItem("trackProject", propId);

    console.log("checking senderUserId adn trackproject",userId,propId)
  }, []);


  const claimupdateLANDs = async () => {
      if (senderUserId && trackProject) {
        try {
          if (user?.uid === senderUserId) {
            console.log("Referred user cannot be the sender. Exiting.");
            return;
          }
    
          // Query Firestore for matching documents
          const q = query(
            collection(db, "landRewards"),
            where("userId", "==", senderUserId),
            where("referredUserId", "==", user?.uid),
            where("projectId", "==", trackProject),
            where("activity", "==", "REDEEM"),
            orderBy("createdAt", "asc")
          );
    
          const querySnapshot = await getDocs(q);
    
          const matchingDocs = [];
          querySnapshot.forEach((docSnap) => {
            matchingDocs.push({ id: docSnap.id, ...docSnap.data() });
          });
    
          if (matchingDocs.length > 0) {
            const latestCreatedAt =
              matchingDocs[matchingDocs.length - 1].createdAt;
            const latestCreatedAtDate = new Date(
              latestCreatedAt.seconds * 1000 +
                latestCreatedAt.nanoseconds / 1000000
            );
            const now = new Date();
            const timeDifferenceMillis = Math.abs(now - latestCreatedAtDate);
            const minutesDifference = timeDifferenceMillis / (1000 * 60);
    
            if (Number(minutesDifference) > 1440) {
              await claimaddNewRewardAndUpdateRealProperty(user);
            } else {
              console.log(
                "You can only redeem LANDs once every 24 hours. Please try again later."
              );
            }
          } else {
            await claimaddNewRewardAndUpdateRealProperty(user);
          }
        } catch (error) {
          console.error("Error updating LANDs:", error);
        }
      }
      else{
        try {
          
    
          // Query Firestore for matching documents
          const q = query(
            collection(db, "landRewards"),
            where("userId", "==", user?.uid),
            where("projectId", "==", selectedProject),
            where("activity", "==", "SELF"),
            orderBy("createdAt", "asc")
          );
    
          const querySnapshot = await getDocs(q);
    
          const matchingDocs = [];
          querySnapshot.forEach((docSnap) => {
            matchingDocs.push({ id: docSnap.id, ...docSnap.data() });
          });
    
          if (matchingDocs.length > 0) {
            const latestCreatedAt =
              matchingDocs[matchingDocs.length - 1].createdAt;
            const latestCreatedAtDate = new Date(
              latestCreatedAt.seconds * 1000 +
                latestCreatedAt.nanoseconds / 1000000
            );
            const now = new Date();
            const timeDifferenceMillis = Math.abs(now - latestCreatedAtDate);
            const minutesDifference = timeDifferenceMillis / (1000 * 60);
    
            if (Number(minutesDifference) > 1440) {
              await claimaddNewRewardAndUpdateRealPropertytoLoggedInUser(user);
            } else {
              console.log(
                "You can only redeem LANDs once every 24 hours. Please try again later."
              );
            }
          } else {
            await claimaddNewRewardAndUpdateRealPropertytoLoggedInUser(user);
          }
        } catch (error) {
          console.error("Error updating LANDs:", error);
        }
    };
    
    }
  
    const claimaddNewRewardAndUpdateRealPropertytoLoggedInUser=async(user)=>{
      try {
        // Add a new document to the `landRewards` collection
        await addDoc(collection(db, "landRewards"), {
          LANDs: 1,
          activity: "CLAIM",
          createdAt: new Date(),
          projectId: selectedProject,
          referralLink: "",
          referredUserId:"",
          userId: user?.uid,
        });
  
        
        console.log("New document created in landRewards.");
    
        // Update `realProperty` collection
        const realPropertyQuery = query(
          collection(db, "realProperty"),
          where("propertyId", "==", selectedProject)
        );
    
        const realPropertySnapshot = await getDocs(realPropertyQuery);
    
        if (!realPropertySnapshot.empty) {
          const realPropertyDoc = realPropertySnapshot.docs[0];
          const currentLANDs = realPropertyDoc.data().LANDs;
    
          if ( Number(currentLANDs) > 0) {
            await updateDoc(realPropertyDoc.ref, {
              LANDs: Number(currentLANDs) - 1,
            });
            console.log("Updated LANDs in realProperty.");
          } else {
            console.warn("LANDs value is not a valid number or is already zero.");
          }
        } else {
          console.error("No matching document found in realProperty.");
        }
    
      
      } catch (error) {
        console.error("Error adding new reward or updating realProperty:", error);
      }
    }
  
  
    const claimaddNewRewardAndUpdateRealProperty = async (user) => {
      try {
        // Add a new document to the `landRewards` collection
        await addDoc(collection(db, "landRewards"), {
          LANDs: 1,
          activity: "CLAIM",
          createdAt: new Date(),
          projectId: trackProject,
          referralLink: "",
          referredUserId: user?.uid || "",
          userId: senderUserId,
        });
  
        await addDoc(collection(db, "landRewards"), {
          LANDs: 1,
          activity: "CLAIM",
          createdAt: new Date(),
          projectId: trackProject,
          referralLink: "",
          referredUserId: senderUserId || "",
          userId: user?.uid,
        });
  
        
        console.log("New document created in landRewards.");
    
        // Update `realProperty` collection
        const realPropertyQuery = query(
          collection(db, "realProperty"),
          where("propertyId", "==", trackProject)
        );
    
        const realPropertySnapshot = await getDocs(realPropertyQuery);
    
        if (!realPropertySnapshot.empty) {
          const realPropertyDoc = realPropertySnapshot.docs[0];
          const currentLANDs = realPropertyDoc.data().LANDs;
    
          if (Number(currentLANDs) > 0) {
            await updateDoc(realPropertyDoc.ref, {
              LANDs: Number(currentLANDs) - 2,
            });
            console.log("Updated LANDs in realProperty.");
          } else {
            console.warn("LANDs value is not a valid number or is already zero.");
          }
        } else {
          console.error("No matching document found in realProperty.");
        }
    
        // Clear localStorage items
        localStorage.removeItem("senderUserId");
        localStorage.removeItem("trackProject");
      } catch (error) {
        console.error("Error adding new reward or updating realProperty:", error);
      }
    };


     const updateLANDs = async () => {
         
          
          if (senderUserId && trackProject) {
            try {
              if (user?.uid === senderUserId) {
                console.log("Referred user cannot be the sender. Exiting.");
                return;
              }
        
              // Query Firestore for matching documents
              const q = query(
                collection(db, "landRewards"),
                where("userId", "==", senderUserId),
                where("referredUserId", "==", user?.uid),
                where("projectId", "==", trackProject),
                
              );
        
              const querySnapshot = await getDocs(q);
        
              const matchingDocs = [];
              querySnapshot.forEach((docSnap) => {
                matchingDocs.push({ id: docSnap.id, ...docSnap.data() });
              });
              await addNewRewardAndUpdateRealProperty(user);
              
            } catch (error) {
              console.error("Error updating LANDs:", error);
            }
          }
          else{
            try {
              
        
              // Query Firestore for matching documents
              const q = query(
                collection(db, "landRewards"),
                where("userId", "==", user?.uid),
                where("projectId", "==", selectedProject),
                
              );
        
              const querySnapshot = await getDocs(q);
        
              const matchingDocs = [];
              querySnapshot.forEach((docSnap) => {
                matchingDocs.push({ id: docSnap.id, ...docSnap.data() });
              });
    
              console.log(matchingDocs)
        
              if (matchingDocs.length > 0) {
                alert("you've already sent an enquiry for this project.Click CLAIM LAND if you want to get more rewards");
                return
              } 
               await addNewRewardAndUpdateRealPropertytoLoggedInUser(user);
              
            } catch (error) {
              console.error("Error updating LANDs:", error);
            }
        };
        
        }
      
        const addNewRewardAndUpdateRealPropertytoLoggedInUser=async(user)=>{
          try {
            const landRewardsQuery = query(
              collection(db, "landRewards"),
              where("userId", "==", user?.uid),
              where("projectId", "==", selectedProject)
            );
        
            const landRewardsSnapshot = await getDocs(landRewardsQuery);
        
            if (!landRewardsSnapshot.empty) {
             alert("you've already sent an enquiry for this project.Click CLAIM LAND if you want to get more rewards");
              return; // Exit if reward already exists
            }
    
    
    
    
            // Add a new document to the `landRewards` collection
            await addDoc(collection(db, "landRewards"), {
              LANDs: 5,
              activity: "ENQUIRE",
              createdAt: new Date(),
              projectId: selectedProject,
              referralLink: "",
              referredUserId:"",
              userId: user?.uid,
            });
      
            
            console.log("New document created in landRewards.");
        
            // Update `realProperty` collection
            const realPropertyQuery = query(
              collection(db, "realProperty"),
              where("propertyId", "==", selectedProject)
            );
        
            const realPropertySnapshot = await getDocs(realPropertyQuery);
        
            if (!realPropertySnapshot.empty) {
              const realPropertyDoc = realPropertySnapshot.docs[0];
              const currentLANDs = realPropertyDoc.data().LANDs;
        
              if (Number(currentLANDs) > 0) {
                await updateDoc(realPropertyDoc.ref, {
                  LANDs: Number(currentLANDs) - 5,
                });
                console.log("Updated LANDs in realProperty.");
              } else {
                console.warn("LANDs value is not a valid number or is already zero.");
              }
            } else {
              console.error("No matching document found in realProperty.");
            }
        
          
          } catch (error) {
            console.error("Error adding new reward or updating realProperty:", error);
          }
        }
      
      
        const addNewRewardAndUpdateRealProperty = async (user) => {
          try {
            // Query landRewards for senderUserId
            const landRewardsQuery1 = query(
              collection(db, "landRewards"),
              where("userId", "==", senderUserId),
              where("projectId", "==", trackProject)
            );
            const landRewardsSnapshot1 = await getDocs(landRewardsQuery1);
        
            // Query landRewards for user
            const landRewardsQuery2 = query(
              collection(db, "landRewards"),
              where("userId", "==", user?.uid),
              where("projectId", "==", trackProject)
            );
            const landRewardsSnapshot2 = await getDocs(landRewardsQuery2);
        
            // If both queries return results, clear localStorage and exit
            if (!landRewardsSnapshot1.empty && !landRewardsSnapshot2.empty) {
              localStorage.removeItem("senderUserId");
              localStorage.removeItem("trackProject");
              return;
            }
        
            // Check and handle landRewards for senderUserId
            if (landRewardsSnapshot1.empty) {
              await addDoc(collection(db, "landRewards"), {
                LANDs: 5,
                activity: "ENQUIRE",
                createdAt: new Date(),
                projectId: trackProject,
                referralLink: "",
                referredUserId: user?.uid || "",
                userId: senderUserId,
              });
              console.log("New document created in landRewards for senderUserId.");
        
              const realPropertyQuery = query(
                collection(db, "realProperty"),
                where("propertyId", "==", trackProject)
              );
              const realPropertySnapshot = await getDocs(realPropertyQuery);
        
              if (!realPropertySnapshot.empty) {
                const realPropertyDoc = realPropertySnapshot.docs[0];
                const currentLANDs = realPropertyDoc.data().LANDs;
        
                if (Number(currentLANDs) > 0) {
                  await updateDoc(realPropertyDoc.ref, {
                    LANDs: Number(currentLANDs) - 5,
                  });
                  console.log("Updated LANDs in realProperty for senderUserId.");
                } else {
                  console.warn("LANDs value is not a valid number or is already zero.");
                }
              } else {
                console.error("No matching document found in realProperty for senderUserId.");
              }
            }
        
            // Check and handle landRewards for user
            if (landRewardsSnapshot2.empty) {
              await addDoc(collection(db, "landRewards"), {
                LANDs: 5,
                activity: "ENQUIRE",
                createdAt: new Date(),
                projectId: trackProject,
                referralLink: "",
                referredUserId: senderUserId || "",
                userId: user?.uid,
              });
              console.log("New document created in landRewards for user.");
        
              const realPropertyQuery = query(
                collection(db, "realProperty"),
                where("propertyId", "==", trackProject)
              );
              const realPropertySnapshot = await getDocs(realPropertyQuery);
        
              if (!realPropertySnapshot.empty) {
                const realPropertyDoc = realPropertySnapshot.docs[0];
                const currentLANDs = realPropertyDoc.data().LANDs;
        
                if (Number(currentLANDs) > 0) {
                  await updateDoc(realPropertyDoc.ref, {
                    LANDs: Number(currentLANDs) - 5,
                  });
                  console.log("Updated LANDs in realProperty for user.");
                } else {
                  console.warn("LANDs value is not a valid number or is already zero.");
                }
              } else {
                console.error("No matching document found in realProperty for user.");
              }
            }
        
            // Clear localStorage items
            localStorage.removeItem("senderUserId");
            localStorage.removeItem("trackProject");
          } catch (error) {
            console.error("Error adding new reward or updating realProperty:", error);
          }
        };


  const fetchAllData = async () => {
    try {
      const realPropertyRef = collection(db, "realProperty");
      
      const querySnapshot = await getDocs(realPropertyRef);
  
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Document ID
        ...doc.data(), // Document data
      }));
      const decodedTitle = sanitizedProjectName.replace(/-/g, ' ');
      console.log(decodedTitle)
      const listing = data.find((item) => item.name === decodedTitle);
  
      setPropertyDataAvailable(listing); 
    } catch (err) {
      console.error("Error fetching property data:", err);
    }
  };
  useEffect(() => {
    fetchAllData()
  }, [db]);

  // State to track the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to handle going to the next image
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to handle going to the previous image
  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleClaimLand=async()=>{
    alert("You are already Logged In")
     await claimupdateLANDs()
    
  }

  const handleEnquire=async()=>{
    alert("You are already Logged In")
    await updateLANDs()

    

  }

 

 /*  if (!propertyDataAvailable) {
    // Redirect to a "Not Found" page if the id doesn't match any listing
    return <Navigate to="/404" replace />;
  }  */

  return (
    <>
      <Header>
        <HeaderContent>
          <Logo src={logo} alt="Thirdeye" onClick={()=>navigate("/")} />
          {isLoggedIn && (<h3
            className="flex items-center gap-2 text-blue-500 text-xl font-bold cursor-pointer hover:text-blue-700 hover:scale-105 transition-all duration-300 mr-2"
            onClick={() => {
              navigate("/rewardcustomerdash");
            }}
          >
            Dashboard
          </h3>)}
        </HeaderContent>
      </Header>
      <div className="details-container">
        <div className="details-header">
          <h1 className="details-title">{decodedTitle}</h1>
          <p className="details-location">
            Pochampally Road, Near Ramoji Film City, Telangana{" "}
          </p>
        </div>
        <div className="photo-map">
          {/* Image Section */}
          <div className="photo">
            <button
              onClick={prevImage}
              title="previous"
              className="arrow left-arrow"
            >
              <ChevronLeftIcon></ChevronLeftIcon>
            </button>
            <img
              src={images[currentImageIndex]}
              alt="Property"
              className="details-image"
            />
            <button
              onClick={nextImage}
              title="next"
              className="arrow right-arrow"
            >
              <ChevronRightIcon></ChevronRightIcon>
            </button>
          </div>

          {/* Map Section */}
          <div className="map">
            <h1 className="layout">Smart Layout</h1>
            <iframe
              src="https://oakbrook.thirdeye.app/"
              title="Smart Layout"
              allowFullScreen
              style={{ width: "100%", height: "400px", padding: "1px" }}
            />
          </div>
        </div>
        <div className="details-info">
          <div className="details-section">
            <h2 className="details-section-title">Property Details</h2>
            <p className="details-description">
              Oak Brook Gardens is a premium real estate project spread over
              <strong> 89+ acres </strong>
              in the serene location of Mehar Nagar, Pochampally Mandal,
              Telangana . Established in the year 2000 , the layout consists of
              168 well-planned plots with
              <strong> GP approvals </strong>
              and survey numbers ranging from
              <strong> 253-260 </strong>. This expansive development offers a
              perfect blend of natural beauty and modern amenities, providing an
              ideal space for building your dream home or weekend getaway.
            </p>
            <p className="details-features">
              {" "}
              <strong>You can Reserve just 1 or more SQYD of land now </strong>
              and enjoy the flexibility of paying later until{" "}
              <strong> 31st Dec 2024. </strong>
            </p>
            <a
              onClick={isLoggedIn?(handleEnquire):(() => {
                setAuthFormOpen(true);
                setRewardFormOpen(false);
                setauthNewUser(false);
                setAlertFormOpen(false);
              })}
              className="details-invest-btn"
            >
              <FaRupeeSign /> <FaRupeeSign />
              Enquire & Earn LAND
            </a>
            {authFormOpen && (
              <div className="reward-auth-deatail-share">
                <div className="detail-share-modal-content">
                  <button style={{display:"flex",justifyContent:"flex-end"}}
                    onClick={() => {
                      setAuthFormOpen(false);
                      setauthNewUser(false);
                      setAlertFormOpen(false);
                    }}
                  >
                     <FaTimesCircle style={{width:"32px",height:"32px"}}/>
                  </button>
                  {!authNewUser && !alertFormOpen && (
                    <Auth
                      setauthNewUser={setauthNewUser}
                      setAlertFormOpen={setAlertFormOpen}
                    />
                  )}
                  {authNewUser && (
                    <AuthSuccess
                      setRewardAuthNewUser={setRewardAuthNewUser}
                      setRewardAlertFormOpen={setRewardAlertFormOpen}
                      setAlertFormOpen={setAlertFormOpen}
                      setauthNewUser={setauthNewUser}
                      rewardFormOpen={rewardFormOpen}
                    />
                  )}
                  {alertFormOpen && (
                    <AlertComponent
                      setRewardAuthNewUser={setRewardAuthNewUser}
                      setRewardAlertFormOpen={setRewardAlertFormOpen}
                      setAlertFormOpen={setAlertFormOpen}
                      setauthNewUser={setauthNewUser}
                      rewardFormOpen={rewardFormOpen}
                      setRewardFormOpen={setRewardFormOpen}
                      setAuthFormOpen={setAuthFormOpen}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="details-stats">
            <h2 className="details-section-title">Sale Information</h2>
            <ul className="details-stats-list">
              <li className="details-stats-item">
                <strong>Total Unit:</strong> 1000 SQYD
              </li>
              <li className="details-stats-item">
                <strong>Current Sale:</strong> 611 SQYDs units sold
              </li>
              <li className="details-stats-item">
                <strong>Sale Period:</strong> Oct 1, 2024 - Dec 31, 2024
              </li>
              <li className="details-stats-item">
                <strong>Selling Rate:</strong> ₹6516
              </li>
              <li className="details-stats-item">
                <strong>Location:</strong> Pochampally Road, Near Ramoji Film
                City, Telangana
              </li>
            </ul>
            <a
              onClick={isLoggedIn?(handleClaimLand):(() => {
               
                setAuthFormOpen(false);
                setRewardFormOpen(true);
                setRewardAuthNewUser(false);
                setRewardAlertFormOpen(false);
              })}
              className="details-invest-btn"
            >
              <FaRupeeSign /> Claim Your LAND{" "}
            </a>
            {rewardFormOpen && (
              <div className="reward-auth-deatail-share">
                <div className="detail-share-modal-content">
                  <button  style={{display:"flex",justifyContent:"flex-end"}}
                    onClick={() => {
                      setRewardAlertFormOpen(false);
                      setRewardFormOpen(false);
                      setRewardAuthNewUser(false);
                      setRewardAlertFormOpen(false);
                    }}
                  >
                    <FaTimesCircle style={{width:"32px",height:"32px"}}/>
                  </button>
                  {!rewardAuthNewUser && !rewardAlertFormOpen && (
                    <Auth2
                      setRewardAuthNewUser={setRewardAuthNewUser}
                      setRewardAlertFormOpen={setRewardAlertFormOpen}
                    />
                  )}
                  {rewardAuthNewUser && (
                    <AuthSuccess
                      setRewardAuthNewUser={setRewardAuthNewUser}
                      setRewardAlertFormOpen={setRewardAlertFormOpen}
                      setAlertFormOpen={setAlertFormOpen}
                      setauthNewUser={setauthNewUser}
                      rewardFormOpen={rewardFormOpen}
                    />
                  )}
                  {rewardAlertFormOpen && (
                    <AlertComponent
                      setRewardAuthNewUser={setRewardAuthNewUser}
                      setRewardAlertFormOpen={setRewardAlertFormOpen}
                      setAlertFormOpen={setAlertFormOpen}
                      setauthNewUser={setauthNewUser}
                      rewardFormOpen={rewardFormOpen}
                      setRewardFormOpen={setRewardFormOpen}
                      setAuthFormOpen={setAuthFormOpen}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OakBrookGarden;
