import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import {
  doc,
  getDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import logo from "../images/thirdeye.png";
import favlogo from "../images/favicon.png";
import { updateDoc, setDoc } from "firebase/firestore";
import { FaBars, FaGift, FaHome, FaTag } from "react-icons/fa";

import icodetails from "../images/icons/ico-details.jpg";
import icocoin from "../images/icons/rupee-coin.svg";
import icolocation from "../images/icons/ico-location.jpg";
import icobin from "../images/icons/ico-bin.jpg";
import green_verified from "../images/green_verified.png";
import red_not_verified from "../images/red_not_verified.png";
import { Tooltip } from "@mui/material";

import { getStorage, ref, deleteObject } from "firebase/storage";

import { AiFillInfoCircle as DetailsIcon } from "react-icons/ai";
import { FaBitcoin as CryptoIcon } from "react-icons/fa";
import { MdLocationOn as LocationIcon } from "react-icons/md";
import { AiFillDelete as BinIcon } from "react-icons/ai";

import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Web3 from "web3";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
// import BigNumber from "bignumber.js";
// import contractData from "../blockchain/ERC404Factory.json";
// import thirdEyeMarketAddress from "../blockchain/ThirdEyeMarket.json";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import styled from "styled-components";
import {
  IconButton,
  Logo1,
  LogoContainer,
  Logoim,
  MenuItem,
  Modal,
  ModalButton,
  ModalButtons,
  ModalContent,
  OfferDetailsColumn,
  OfferDetailsContainer,
  OfferIconImage,
  OfferIconRow,
  PropertyBusinessId,
  PropertyContainer,
  PropertyDetails,
  PropertyName1,
  Sidebar,
  Text,
} from "./customerdashboard";
import { Help } from "@mui/icons-material";
import { Container1 } from "./customerdashboard";
export const PropertyName = styled.span`
  font-size: 1.125rem; /* Tailwind text-lg */
  font-weight: bold;
  color: #6b7280; /* Tailwind text-gray-500 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Apple";
`;
export const PropertyNameWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 50;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

// Nav
export const Nav = styled.nav`
  background: white;
  border-bottom: 1px solid #e2e8f0;
  padding: 0.625rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 576px) {
    padding: 0.625rem 1rem;
  }

  @media (max-width: 300px) {
    flex-direction: column;
    padding: 0.5rem;
  }
`;

// FlexContainer
export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

// FlexStart
export const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

// LogoImage
export const LogoImage = styled.img`
  height: 2.75rem;
  width: auto;

  @media (max-width: 576px) {
    height: 2.5rem;
  }

  @media (max-width: 300px) {
    height: 1.75rem; /* Adjust height to ensure visibility on very small screens */
  }
`;

// Button
export const Button = styled.button`
  padding: 0.5rem; /* p-2 */
  margin-right: 0.25rem; /* mr-1 */
  color: #6b7280; /* text-gray-500 */
  border-radius: 0.375rem; /* rounded-lg */
  &:hover {
    color: #111827; /* hover:text-gray-900 */
    background-color: #f9fafb; /* hover:bg-gray-100 */
  }
  &:focus {
    outline: 4px solid #d1d5db; /* focus:ring-4 focus:ring-gray-300 */
  }
  @media (prefers-color-scheme: dark) {
    color: #9ca3af; /* dark:text-gray-400 */
    &:hover {
      color: white; /* dark:hover:text-white */
      background-color: #374151; /* dark:hover:bg-gray-700 */
    }
    &:focus {
      outline: 4px solid #4b5563; /* dark:focus:ring-gray-600 */
    }
  }
`;

// NotificationDropdown
export const NotificationDropdown = styled.div`
  position: absolute;
  right: 0;
  margin-top: 0.5rem; /* mt-2 */
  overflow: hidden;
  z-index: 50;
  margin: 1rem 0; /* my-4 */
  max-width: 16rem; /* max-w-sm */
  text-base;
  list-style-type: none;
  background-color: white;
  border-radius: 0.375rem; /* rounded */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); /* shadow-lg */
  border-color: #e2e8f0; /* divide-gray-100 */
  @media (prefers-color-scheme: dark) {
    border-color: #4b5563; /* dark:divide-gray-600 */
    background-color: #374151; /* dark:bg-gray-700 */
  }
`;

// NotificationHeader
export const NotificationHeader = styled.div`
  display: block;
  padding: 0.5rem 1rem; /* py-2 px-4 */
  font-size: 1rem; /* text-base */
  font-weight: 500; /* font-medium */
  text-align: center;
  color: #4b5563; /* text-gray-700 */
  background-color: #f9fafb; /* bg-gray-50 */
  @media (prefers-color-scheme: dark) {
    background-color: #374151; /* dark:bg-gray-700 */
    color: #9ca3af; /* dark:text-gray-400 */
  }
`;

// NotificationLink
export const NotificationLink = styled.a`
  display: block;
  padding: 0.5rem 1rem; /* py-2 px-4 */
  font-size: 1rem; /* text-base */
  font-weight: 500; /* font-medium */
  text-align: center;
  color: #1f2937; /* text-gray-900 */
  background-color: #f9fafb; /* bg-gray-50 */
  &:hover {
    background-color: #f3f4f6; /* hover:bg-gray-100 */
    text-decoration: underline; /* dark:hover:underline */
  }
  @media (prefers-color-scheme: dark) {
    background-color: #374151; /* dark:bg-gray-700 */
    color: white; /* dark:text-white */
  }
`;

// ProfileButton
export const ProfileButton = styled.button`
  display: flex;
  margin: 0 0.75rem; /* mx-3 */
  font-size: 0.875rem; /* text-sm */
  background-color: #2d3748; /* bg-gray-800 */
  border-radius: 50%; /* rounded-full */
  @media (min-width: 768px) {
    margin-right: 0; /* md:mr-0 */
  }
  &:focus {
    outline: 4px solid #d1d5db; /* focus:ring-4 focus:ring-gray-300 */
    @media (prefers-color-scheme: dark) {
      outline: 4px solid #4b5563; /* dark:focus:ring-gray-600 */
    }
  }
`;

// ProfileIcon
export const ProfileIcon = styled.span`
  display: block;
  width: 1.75rem; /* w-7 */
  height: 1.75rem; /* h-7 */
  border-radius: 50%; /* rounded-full */
  font-size: 1.125rem; /* text-lg */
  line-height: 1.75rem; /* leading-7 */
  text-align: center;
  color: #6b7280; /* text-gray-500 */
  background-color: #f9fafb; /* bg-gray-50 */
  &:hover {
    background-color: #f3f4f6; /* hover:bg-gray-100 */
  }
`;

// DropdownMenu
export const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  margin-top: 0.5rem; /* mt-2 */
  overflow: hidden;
  z-index: 50;
  margin: 1rem 0; /* my-4 */
  width: 14rem; /* w-56 */
  text-base;
  list-style-type: none;
  background-color: white;
  border-radius: 0.375rem; /* rounded */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); /* shadow */
  border-color: #e2e8f0; /* divide-gray-100 */
  @media (prefers-color-scheme: dark) {
    border-color: #4b5563; /* dark:divide-gray-600 */
    background-color: #374151; /* dark:bg-gray-700 */
  }
`;

// UserDetails
export const UserDetails = styled.div`
  padding: 0.75rem 1rem; /* py-3 px-4 */
`;

// UserName
export const UserName = styled.span`
  display: block;
  font-size: 0.875rem; /* text-sm */
  font-weight: 600; /* font-semibold */
  color: #1f2937; /* text-gray-900 */
  @media (prefers-color-scheme: dark) {
    color: white; /* dark:text-white */
  }
`;

// UserCity
export const UserCity = styled.span`
  display: block;
  font-size: 0.875rem; /* text-sm */
  color: #6b7280; /* text-gray-500 */
  white-space: nowrap; /* truncate */
  @media (prefers-color-scheme: dark) {
    color: #9ca3af; /* dark:text-gray-400 */
  }
`;

// MenuItem
export const MenuItem1 = styled.a`
  display: block;
  padding: 0.5rem 1rem; /* py-2 px-4 */
  font-size: 0.875rem; /* text-sm */
  color: #6b7280; /* text-gray-500 */
  &:hover {
    background-color: #f3f4f6; /* hover:bg-gray-100 */
  }
  @media (prefers-color-scheme: dark) {
    color: #9ca3af; /* dark:text-gray-400 */
    &:hover {
      background-color: #4b5563; /* dark:hover:bg-gray-600 */
      color: white; /* dark:hover:text-white */
    }
  }
`;

// MenuItemLink
export const MenuItemLink = styled(Link)`
  display: block;
  padding: 0.5rem 1rem; /* py-2 px-4 */
  font-size: 0.875rem; /* text-sm */
  color: #6b7280; /* text-gray-500 */
  &:hover {
    background-color: #f3f4f6; /* hover:bg-gray-100 */
  }
  @media (prefers-color-scheme: dark) {
    color: #9ca3af; /* dark:text-gray-400 */
    &:hover {
      background-color: #4b5563; /* dark:hover:bg-gray-600 */
      color: white; /* dark:hover:text-white */
    }
  }
`;

// MenuItemRed
export const MenuItemRed = styled.a`
  display: block;
  padding: 0.5rem 1rem; /* py-2 px-4 */
  font-size: 0.875rem; /* text-sm */
  color: #ef4444; /* text-red-500 */
  &:hover {
    background-color: #f3f4f6; /* hover:bg-gray-100 */
  }
  @media (prefers-color-scheme: dark) {
    color: #f87171; /* dark:text-red-400 */
    &:hover {
      background-color: #4b5563; /* dark:hover:bg-gray-600 */
      color: white; /* dark:hover:text-white */
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 120px;

  @media (max-width: 400px) {
    padding: 10px;
    margin-top: 60px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media (max-width: 400px) {
    grid-template-columns: 1fr;
    gap: 8px;
  }
`;

export const InputGroup = styled.div`
  margin-bottom: 16px;

  @media (max-width: 400px) {
    margin-bottom: 8px;
  }
`;

export const Label = styled.h4`
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  &:focus {
    outline: none;
  }

  @media (max-width: 400px) {
    padding: 6px 10px;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 8px 12px;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  &:focus {
    outline: none;
  }

  @media (max-width: 400px) {
    padding: 6px 10px;
  }
`;

const Button1 = styled.button`
  margin-right: 0;
  margin-top: 16px;
  padding: 8px 32px;
  border: none;
  font-size: 14px;
  font-weight: medium;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  background-color: ${(props) => (props.isEditing ? "#48bb78" : "#4299e1")};
  &:hover {
    background-color: ${(props) => (props.isEditing ? "#38a169" : "#3182ce")};
  }

  @media (max-width: 400px) {
    padding: 6px 16px;
    font-size: 12px;
  }
`;

const FlexContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  height: 100%;
`;

const ResponsiveContainer = styled.div`
  width: 100%;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 120px;
  max-width: 600px;

  @media (max-width: 400px) {
    margin-top: 60px;
    padding: 2px;
  }
`;

const ResponsiveIframeWrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;
  text-align: left;
`;

const ResponsiveIframe = styled.iframe`
  width: 100%;
  max-width: 500px;
  height: 550px;
`;

export const SectionTitleWrapper = styled.div`
  padding: 5px;
`;

export const SectionTitle = styled.h2`
  text-align: center;
  cursor: pointer;
`;

export const SectionDivider = styled.hr`
  width: 100%;
  size: 3;
`;

const PlanLink = styled.p`
  text-decoration: underline;
  cursor: pointer;
  color: black;
  &:hover {
    color: red;
  }
`;

const NoPlanText = styled.div`
  text-align: center;
  opacity: 0.5;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
  align-items: center;

  @media (max-width: 400px) {
    justify-content: center;
  }
`;

const ImageContainer = styled.div`
  img {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: ${(props) => (props.isExpanded ? "2px solid blue" : "none")};
  }
`;

const NoImageText = styled.p`
  opacity: 0.5;
  padding-left: 50%;
  text-align: center;
`;

const ExpandedImageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
`;

const ExpandedImageContainer = styled.div`
  position: relative;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const ExpandedImage = styled.img`
  transform: scale(${(props) => props.zoomLevel});
  transform-origin: center;
  max-width: 100%;
  max-height: 100%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 10px;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background-color: transparent;
`;

//offer

const PropertyContainerUnique = styled.div`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PropertyDetailsUnique = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 400px) {
    gap: 4px;
  }
`;

const PropertyNameUnique = styled.span`
  font-size: 1.125rem;
  font-weight: bold;
  color: #6b7280;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 400px) {
    font-size: 1rem;
  }
`;

export const BusinessIdUnique = styled.span`
  font-size: 1rem;
  color: #9ca3af;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 400px) {
    font-size: 0.875rem;
  }
`;

export const ContainerUnique = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media (max-width: 400px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
`;

export const FieldContainerUnique = styled.div`
  margin-bottom: 16px;
  text-align: left;

  @media (max-width: 400px) {
    margin-bottom: 12px;
  }
`;

export const LabelUnique = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4a5568;
`;

export const InputUnique = styled.input`
  margin-top: 4px;
  padding: 8px;
  width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;

  &:focus {
    outline: none;
    border-color: #a0aec0;
  }
`;

export const ButtonContainerUnique = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 16px;

  @media (max-width: 400px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const AcceptButtonUnique = styled.button`
  background-color: #48bb78;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  cursor: pointer;

  &:hover {
    background-color: #38a169;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const RejectButtonUnique = styled.button`
  background-color: #f56565;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  cursor: pointer;

  &:hover {
    background-color: #e53e3e;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`;

const Dashboard = () => {
  const [localStateMinimumArea, setLocalStateMinimuArea] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showAllNotifications, setShowAllNotifications] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [user, setUser] = useState(null);
  const notificationRef = useRef(null);
  const profileRef = useRef(null);
  const db = getFirestore();
  const navigate = useNavigate();
  const [businessName, setBusinessName] = useState("");
  const [realPropertybusinessId, setrealPropertyBusinessId] = useState("");
  const [realPropertyName, setrealPropertyName] = useState("");
  const [hasRealProperty, setHasRealProperty] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState("Properties");
  const [isAssetCreated, setIsAssetCreated] = useState({});
  const [isSaleCreated, setIsSaleCreated] = useState({});
  const [propertyNames, setPropertyNames] = useState({});
  const [businessIds, setBusinessIds] = useState({});
  const [walletAddress, setWalletAddress] = useState("");
  const [isWalletAddressEditable, setIsWalletAddressEditable] = useState(true);
  // state variables for each dropdown
  const [locationOpen, setLocationOpen] = useState(null);
  const [coinOpen, setCoinOpen] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(null);
  const [smartLayout, setSmartLayout] = useState("");
  // Coin
  const [blockchain, setBlockchain] = useState("");
  const [numTokens, setNumTokens] = useState("");
  const [assets, setAssets] = useState([
    { blockchain: "", numTokens: "", tokenName: "", tokenAddress: "" },
  ]);
  const [isEdited, setIsEdited] = useState(false);
  const [isSaleEdited, setIsSaleEdited] = useState(false);
  //highlight
  const [detailsHighlighted, setDetailsHighlighted] = useState(false);
  const [coinHighlighted, setCoinHighlighted] = useState(false);
  const [locationHighlighted, setLocationHighlighted] = useState(false);
  const [binHighlighted, setBinHighlighted] = useState(false);
  const [pressedPropertyId, setPressedPropertyId] = useState(null);
  const [pressedDetailsId, setPressedDetailsId] = useState(null);
  const [pressedCoinId, setPressedCoinId] = useState(null);
  const [pressedLocationId, setPressedLocationId] = useState(null);
  const [pressedBinId, setPressedBinId] = useState(null);
  // wallet
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isCreatingAsset, setIsCreatingAsset] = useState(false);
  const [isCreatingSale, setIsCreatingSale] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [iswalletcategory, setIswalletcategory] = useState(false);
  //Sale
  const [noBlockChain, setNoBlockChain] = useState(false);
  const [sales, setSales] = useState([
    {
      baseCurrency: "",
      marketRate: "",
      minSaleAmount: "",
      saleStart: "",
      saleEnd: "",
      sellingArea: "",
      sellingRate: "",
      totalArea: "",
      minimumArea: "",
      fractions: "",
      status: "",
      maxDiscount: "",
      maxDiscountUnits: "",
    },
  ]);

  useEffect(()=>{
    localStorage.removeItem("rewardFormOpen")
  },[])

  // buttton

  const [isButtonActive, setIsButtonActive] = useState(false);
  const thirdeye_factory_contract =
    "0x462D68B562842D38212f9cEB266A17341285aF4c";
  const thirdeye_market_contract = "0x8507B0cc80566d35Acdf833bD2Fe90f2Cca56bf1";
  // loading

  const [PropLoading, setPropLoading] = useState(true);
  const [flag, setFlag] = useState(false);
  const [userProfileOpen, setUserProfileOpen] = useState(false);
  // is editing  userprofile editiing

  //const [userProfileOpen, setUserProfileOpen] = useState(false);
  const [userData, setUserData] = useState({ mobile: "", name: "", city: "" });
  //const [isEditing, setIsEditing] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [userName, setUserName] = useState("");
  const [userCity, setUserCity] = useState("");

  useEffect(() => {
    if (userProfileOpen) {
      const fetchUserprofileData = async () => {
        try {
          const auth = getAuth();
          const user = auth.currentUser; // Get the currently authenticated user

          if (user) {
            // Check if the user is authenticated
            const db = getFirestore();
            const profileRef = collection(db, "users"); // Reference to the "users" collection
            const profileQuery = query(
              profileRef,
              where("id", "==", user.uid) // Match the UID of the current user
            );

            const querySnapshot = await getDocs(profileQuery);

            if (!querySnapshot.empty) {
              // Check if any documents match the query
              querySnapshot.forEach((doc) => {
                const data = doc.data();
                setUserData({
                  mobile: data.mobile,
                  name: data.name,
                  city: data.city,
                });
                setUserName(data.name);
                setUserCity(data.city);
              });
            } else {
              console.log("No such document found for the current user!");
            }
          } else {
            console.log("No authenticated user found!");
          }
        } catch (error) {
          console.error("Error fetching user profile data:", error);
        }
      };

      fetchUserprofileData();
    }
  }, [userProfileOpen]);

  const handleprofileSave = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const db = getFirestore();

    try {
      await updateDoc(doc(db, "users", user.uid), {
        name: userName,
        city: userCity,
      });

      // const userDoc = doc(db, "users", user.uid);
      // try {
      //   await updateDoc(userDoc, {
      //     name: userName,
      //     city: userCity,
      //   });
      setUserData({ ...user, name: userName, city: userCity }); // Update the local state
      setIsProfileEditing(false);

      setUserProfileOpen(false);
      console.log("User details updated successfully");
    } catch (error) {
      alert("Error updating document: ", error);
    }
  };

  // const [expandedImage, setExpandedImage] = useState(null);

  // const handleImageClick = (image) => {
  //   setExpandedImage(expandedImage === image ? null : image);

  // }

  // const [expandedImage, setExpandedImage] = useState(null);
  // const [zoomed, setZoomed] = useState(false);

  // const handleImageClick = (imageSrc) => {
  //   setExpandedImage(expandedImage === imageSrc ? null : imageSrc);
  //   setZoomed(false); // Reset zoom when changing the image
  // };

  // const toggleZoom = () => {
  //   setZoomed(!zoomed);
  // };

  const [expandedImage, setExpandedImage] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [flagVerify, setFlagVerify] = useState(true);

  const handleImageClick = (imageSrc) => {
    // setExpandedImage(expandedImage === imageSrc ? null : imageSrc);
    setExpandedImage((prev) => (prev === imageSrc ? null : imageSrc));
    setZoomLevel(1); // Reset zoom when changing the image
  };

  const handleWheel = (event) => {
    event.preventDefault();
    setZoomLevel((prevZoom) => {
      let newZoom = prevZoom + (event.deltaY > 0 ? -0.1 : 0.1);
      newZoom = Math.max(1, Math.min(newZoom, 3)); // Set min and max zoom levels
      return newZoom;
    });
  };
  const fetchPropertyData = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const db = getFirestore();

    const propertiesRef = collection(db, "realProperty");
    const propertiesQuery = query(
      propertiesRef,
      where("businessId", "==", user.uid)
    );
    const propertiesSnapshot = await getDocs(propertiesQuery);

    const allPropertyNames = propertiesSnapshot.docs.reduce((names, doc) => {
      names[doc.id] = doc.data().name;
      return names;
    }, {});

    const allBusinessIds = propertiesSnapshot.docs.reduce((ids, doc) => {
      ids[doc.id] = doc.data().businessId;
      return ids;
    }, {});

    setPropertyNames(allPropertyNames);
    setBusinessIds(allBusinessIds);
  };

  /* const handleVerify = async (property) => {
    alert("Your Request has been submitted")
    const propRef = doc(db, "realProperty", property.propertyId); // Use dynamic document ID
    await updateDoc(propRef, { verified: true });

    fetchUserData();
    setFlagVerify(true)

   
  }; */
  const handleVerify = async (property) => {
    const auth = getAuth();
    const user = auth.currentUser;
    const db = getFirestore();
    // Alert user about the submission
    alert("Your Request has been submitted");

    const propRef = doc(db, "realProperty", property.propertyId);
    // await updateDoc(propRef, { verified: true });

    const verificationRequestDoc = {
      assignedTo: "",
      createdAt: new Date(),
      propertyId: property.propertyId,
      status: "NEW",
      userId: user.uid,
    };

    await addDoc(collection(db, "verificationRequest"), verificationRequestDoc);

    fetchUserData();

    setFlagVerify(true);
  };

  /* useEffect(()=>{
    fetchUserData()
  },[db,flag]) */

  // const [expandedImage, setExpandedImage] = useState(null);

  // const handleImageClick = (imageSrc) => {
  //   setExpandedImage(expandedImage === imageSrc ? null : imageSrc);
  // };
  // const [popupImage, setPopupImage] = useState(null);

  // const handleImageClick = (image) => {
  //   setPopupImage(image);
  // };

  // const handleClosePopup = () => {
  //   setPopupImage(null);
  // };
  //const contractABI = contractData.abi;
  //const contractABI = thirdEyeMarketAddress.abi;
  //fetch and store all  properties for the current user for faster load

  useEffect(() => {
    const fetchPropertyData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      const db = getFirestore();

      const propertiesRef = collection(db, "realProperty");
      const propertiesQuery = query(
        propertiesRef,
        where("businessId", "==", user.uid)
      );
      const propertiesSnapshot = await getDocs(propertiesQuery);

      const allPropertyNames = propertiesSnapshot.docs.reduce((names, doc) => {
        names[doc.id] = doc.data().name;
        return names;
      }, {});

      const allBusinessIds = propertiesSnapshot.docs.reduce((ids, doc) => {
        ids[doc.id] = doc.data().businessId;
        return ids;
      }, {});

      setPropertyNames(allPropertyNames);
      setPropLoading(false);
      setBusinessIds(allBusinessIds);
    };

    fetchPropertyData();
  }, []);

  //fetch and store all  properties for the current user for faster load end

  //web3
  const handleWalletConnect = async () => {
    setNoBlockChain(false);

    setIswalletcategory(true);
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        setIsWalletConnected(true);

        setWalletAddress(account);
        setIsWalletAddressEditable(false);

        // update tokenAddress field of the assets state
        setAssets((prevAssets) =>
          prevAssets.map((asset, index) =>
            index === 0 ? { ...asset, tokenAddress: account } : asset
          )
        );
      } catch (error) {
        console.error("User denied account access");
      }
    } else {
      console.log("Non-Ethereum browser detected. Try MetaMask");
    }
  };

  const handleNoBlockChain = (property) => {
    setIswalletcategory(false);
    //setIsWalletConnected(false);
    setNoBlockChain(true);
    handleCreateSale(property);
  };

  //web3 end
  //profiledit
  const [isProfileEditing, setIsProfileEditing] = useState(false);
  //details
  const [isEditing, setIsEditing] = useState(false);
  const [editedProperty, setEditedProperty] = useState({});
  const [editedCity, setEditedCity] = useState("");
  const [editedPropertyType, setEditedPropertyType] = useState("");
  const [editedTotalArea, setEditedTotalArea] = useState("");
  const [editedTotalPlots, setEditedTotalPlots] = useState("");
  const [editedSoldPlots, setEditedSoldPlots] = useState("");
  const [currentBlockchain, setCurrentBlockchain] = useState("");

  const toggleLocation = async (propertyId) => {
    setLocationOpen((prev) => (prev === propertyId ? null : propertyId));
    setCoinOpen(null);
    setDetailsOpen(null);

    const propertyDocRef = doc(db, "realProperty", propertyId);
    const propertyDocSnap = await getDoc(propertyDocRef);

    if (propertyDocSnap.exists()) {
      setSmartLayout(propertyDocSnap.data().smartlayout);
    } else {
      console.log("No such document in realProperty collection!");
    }
  };

  const toggleCoin = async (id) => {
    setCoinOpen((prev) => (prev === id ? null : id));
    setIsButtonActive(false);
    setLocationOpen(null);
    setDetailsOpen(null);
    setIswalletcategory(false);
    setNoBlockChain(false);
    /* setIsCreatingAsset(false);
    setIsEdited(true);
    setIsCreatingSale(false);
    setIsSaleEdited(true); */
    const digitalAssetDocSnap = await getDoc(doc(db, "digitalAsset", id));
    const crowdSaleDocSnap = await getDoc(doc(db, "crowdSale", id));

    if (digitalAssetDocSnap.exists()) {
      // Display digital asset details
    } else if (crowdSaleDocSnap.exists()) {
      // Display crowdsale details
    } else {
      console.log("No such document!");
    }
  };

  const fetchBlockchain = async (property) => {
    const digitalAssetDocRef = doc(db, "digitalAsset", property.propertyId);
    const digitalAssetDocSnap = await getDoc(digitalAssetDocRef);

    if (digitalAssetDocSnap.exists()) {
      setCurrentBlockchain(digitalAssetDocSnap.data().blockchain);
    } else {
      console.log("No such document in digitalAsset collection!");
    }
  };

  //create asset multiple

  const handleCreateAsset = (property) => {
    if (assets.length === 0) {
      setAssets((prev) => [
        ...prev,
        { blockchain: "", numTokens: "", tokenName: "", tokenAddress: "" },
      ]);
    }
    setIsCreatingAsset(true);
    fetchBlockchain(property);
  };

  //  const fetchNoBlockChain= async(property)=>{
  //   const crowdSaleDocRef = doc(db, "crowdSale", property.propertyId);
  //   const crowdSaletDocSnap = await getDoc(crowdSaleDocRef);

  //   if (crowdSaletDocSnap.exists()) {
  //     setCurrentBlockchain(crowdSaletDocSnap.data().blockchain);
  //   } else {
  //     console.log("No such document in crowdsale collection!");
  //   }
  // }

  const handleCreateSale = (property) => {
    if (sales.length === 0) {
      setSales((prev) => [
        ...prev,
        {
          baseCurrency: "",
          marketRate: "",
          minSaleAmount: "",
          saleStart: "",
          saleEnd: "",
          sellingArea: "",
          sellingRate: "",
          totalArea: "",
          minimumArea: "",
          fractions: "",

          status: "",
          maxDiscount: "",
          maxDiscountUnits: "",
        },
      ]);
    }
    setIsCreatingSale(true);
  };

  //validate fields

  const validateFields = (assets) => {
    for (let asset of assets) {
      if (
        !asset.blockchain ||
        !asset.walletAddress ||
        !asset.numTokens ||
        !asset.tokenName ||
        !asset.baseCurrency ||
        !asset.basePrice ||
        !asset.tokenAddress
      ) {
        return false;
      }
    }
    return true;
  };
  const saleValidateFields = (sales) => {
    for (let sale of sales) {
      if (
        !sale.baseCurrency ||
        !sale.marketRate ||
        !sale.minSaleAmount ||
        !sale.saleStart ||
        !sale.saleEnd ||
        !sale.sellingArea ||
        !sale.sellingRate ||
        !sale.totalArea ||
        !sale.minimumArea
      ) {
        return false;
      }
    }
    return true;
  };

  //validate fields end

  // handleSubmit web3

  const handleSubmit = async (property) => {
    //setIsCreatingAsset(true)
    //validate if all fields are filled
    if (!validateFields(assets)) {
      alert("You have not filled all the fields.");
      return;
    }

    //check wallet connection
    if (!isWalletConnected) {
      console.error("Wallet is not connected");
      return;
    }

    //check polygon
    const networkVersion = await window.ethereum.request({
      method: "net_version",
    });
    if (networkVersion !== "80002") {
      console.error("Please switch to the Polygon Testnet");
      return;
    }

    //initialize web3
    let web3;
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
      await window.ethereum.request({ method: "eth_requestAccounts" });
    } else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
    } else {
      console.log(
        "No Ethereum interface injected into browser. Consider installing MetaMask or similar."
      );
    }

    const walletAddress = assets[0].walletAddress;
    console.log("walletAddress:", walletAddress);

    //const contractAddress = "0xD9d47DbC256344cb7a1f22922B8176C279bC238C"; //third
    // const contractAddress = "0x462D68B562842D38212f9cEB266A17341285aF4c"; //new

    const PropertyName = property.name;
    const tokenName = assets[0].tokenName;
    const totalTokens = assets[0].numTokens;

    console.log("PropertyName:", PropertyName);
    console.log("tokenName:", tokenName);
    console.log("totalTokens:", totalTokens);
    console.log("thirdeyefactory  contractAddress:", thirdeye_factory_contract);

    try {
      //create ERC404 Token
      const args = [walletAddress, PropertyName, tokenName, totalTokens];
      const data = web3.eth.abi.encodeFunctionCall(
        {
          name: "createERC404Token",
          type: "function",
          inputs: [
            {
              type: "address",
              name: "walletAddress",
            },
            {
              type: "string",
              name: "PropertyName",
            },
            {
              type: "string",
              name: "tokenName",
            },
            {
              type: "uint256",
              name: "totalTokens",
            },
          ],
        },
        args
      );

      const tx = {
        from: walletAddress,
        to: thirdeye_factory_contract,
        data: data,
        gas: 3000000,
      };

      //estimate the gas limit
      const gasEstimate = await web3.eth.estimateGas(tx);
      tx.gas = gasEstimate;

      //receipt
      const transactionReceipt = await web3.eth.sendTransaction(tx);

      //call getDeploy method

      const args2 = [walletAddress];

      const data2 = web3.eth.abi.encodeFunctionCall(
        {
          name: "getDeployedTokens",
          type: "function",
          inputs: [
            {
              type: "address",
              name: "contractAddress",
            },
          ],
        },
        args2
      );

      const tx2 = {
        from: walletAddress,
        to: thirdeye_factory_contract,
        data: data2,
        gas: 3000000,
      };

      //result
      const result = await web3.eth.call(tx2);
      const tokenContractAddress = web3.eth.abi.decodeParameter(
        "address",
        result
      );

      console.log("Deployed Token Contract Address:", tokenContractAddress);

      const tokenAddress = tokenContractAddress;

      //if success update realProperty and digitalAsset
      const propertyDocRef = doc(db, "realProperty", property.propertyId);
      const propertyDocSnap = await getDoc(propertyDocRef);

      if (!propertyDocSnap.data().digitalAsset) {
        await updateDoc(propertyDocRef, {
          digitalAsset: true,
        });
      }

      const digitalAssetCollectionRef = collection(db, "digitalAsset");
      const digitalAssetDocRef = doc(
        digitalAssetCollectionRef,
        property.propertyId
      );
      const digitalAssetDoc = {
        TotalArea: parseInt(property.totalArea),
        blockchain: assets[0].blockchain,
        propertyId: property.propertyId,
        tokenAddress: tokenAddress,
        tokenName: assets[0].tokenName,
        totalTokens: parseInt(assets[0].numTokens),
        baseCurrency: assets[0].baseCurrency,
        basePrice: assets[0].basePrice * 1000000000000000000,
        walletAddress: walletAddress,
      };
      await setDoc(digitalAssetDocRef, digitalAssetDoc, { merge: true });

      setIsAssetCreated((prev) => ({
        ...prev,
        [property.propertyId]: true,
      }));

      setIsCreatingAsset(false);
      setIsEdited(true);
      fetchUserData();
      setIsPopupVisible(true);
    } catch (error) {
      console.error("Failed to create ERC404 token", error);
      alert("Token creation failed");
      return;
    }
  };
  const handleBlockChainSubmit = async (property) => {
    const propertyDocRef = doc(db, "realProperty", property.propertyId);
    const propertyDocSnap = await getDoc(propertyDocRef);

    if (!propertyDocSnap.data().digitalAsset) {
      await updateDoc(propertyDocRef, {
        crowdSale: true,
      });
    }

    const crowdSaleCollectionRef = collection(db, "crowdSale");
    const crowdSaleDocRef = doc(crowdSaleCollectionRef, property.propertyId);
    const crowdSaleDoc = {
      availableUnits: sales[0].sellingArea,
      baseCurrency: sales[0].baseCurrency,
      marketRate: sales[0].marketRate,
      minSaleAmount: sales[0].minSaleAmount,
      saleStart: sales[0].saleStart,
      saleEnd: sales[0].saleEnd,
      sellingArea: sales[0].sellingArea,
      sellingRate: sales[0].sellingRate * 1.0025, //adding 0.25%
      totalArea: parseInt(property.totalArea),
      minimumArea: Number(sales[0].minimumArea),
      fractions: 
        (property.totalArea) /(sales[0].minimumArea)
      ,
      propertyId: property.propertyId,
      currentSaleAmount: "0",
      status: "open",
      maxDiscount: sales[0].maxDiscount,
      maxDiscountUnits: sales[0].maxDiscountUnits,
    };
    await setDoc(crowdSaleDocRef, crowdSaleDoc, { merge: true });

    setIsSaleCreated((prev) => ({
      ...prev,
      [property.propertyId]: true,
    }));

    setIsCreatingSale(false);
    setIsSaleEdited(true);
    fetchNoBlockChainUserData();
    setIsPopupVisible(true);
  };

  //handleSubmit web3 end

  //success Popup
  const SuccessPopup = ({ onClose }) => (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-lg font-bold mb-4">
          Digital Asset Created Successfully
        </h2>
        <button
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
          onClick={onClose}
        >
          Okay
        </button>
      </div>
    </div>
  );
  //success Popup end

  const [selectedBlockchain, setSelectedBlockchain] = useState("");

  //handleSave
  const handleSave = (
    index,
    blockchain,
    numTokens,
    tokenName,
    tokenAddress,
    baseCurrency,
    basePrice
  ) => {
    setAssets((prev) =>
      prev.map((asset, i) =>
        i === index
          ? {
              blockchain,
              numTokens,
              tokenName,
              tokenAddress,
              baseCurrency,
              basePrice,
              walletAddress,
            }
          : asset
      )
    );
    setSelectedBlockchain(blockchain);
  };
  const handleBlockChainSave = (
    index,
    baseCurrency,
    marketRate,
    minSaleAmount,
    saleStart,
    saleEnd,
    sellingArea,
    sellingRate,
    totalArea,
    minimumArea,
    fractions,
    maxDiscount,
    maxDiscountUnits
  ) => {
    console.log("Received minimumArea:", minimumArea);
    console.log("Received fractions:", minimumArea);
    setSales((prev) =>
      prev.map((sale, i) =>
        i === index
          ? {
              baseCurrency,
              marketRate,
              minSaleAmount,
              saleStart,
              saleEnd,
              sellingArea,
              sellingRate,
              totalArea,
              minimumArea,
              fractions,
              maxDiscount,
              maxDiscountUnits,
            }
          : sale
      )
    );
  };
  

  const toggleDetails = (propertyId) => {
    setDetailsOpen((prev) => (prev === propertyId ? null : propertyId));
    setLocationOpen(null);
    setCoinOpen(null);
  };

  //tooge digital assest button
  const toggleButton = (propertyId) => {
    //  setIsButtonActive((prev) => (prev === propertyId ? null : propertyId));
    setIsButtonActive(true);
  };

  const handleEditClick = (property) => {
    setIsEditing(true);
    setEditedProperty({
      ...property,
      totalPlots: property.totalPlots || "",
      soldPlots: property.soldPlots || "",
    });
    setEditedCity(property.city);
    setEditedPropertyType(property.propertyType);
    setEditedTotalArea(property.totalArea);
    setEditedTotalPlots(property.totalPlots);
    setEditedSoldPlots(property.soldPlots);
  };

  const handleSaveClick = async (property) => {
    const propertyDocRef = doc(db, "realProperty", property.propertyId);
    let updatedData = {
      city: editedCity,
      propertyType: editedPropertyType,
      totalArea: editedTotalArea,
    };
    if (editedTotalPlots !== undefined) {
      updatedData.totalPlots = editedTotalPlots;
    }
    if (editedSoldPlots !== undefined) {
      updatedData.soldPlots = editedSoldPlots;
    }
    await updateDoc(propertyDocRef, updatedData);
    setIsEditing(false);

    //update properties state
    setProperties((prevProperties) =>
      prevProperties.map((prevProperty) => {
        if (prevProperty.propertyId === property.propertyId) {
          return { ...prevProperty, ...updatedData };
        } else {
          return prevProperty;
        }
      })
    );
  };

  //details

  const [properties, setProperties] = useState([]);
  const [propertyToDelete, setPropertyToDelete] = useState(null);

  const handleDeleteRealProperty = async (propertyId) => {
    setPropertyToDelete(propertyId);

    setIsModalOpen(true);
  };

  const confirmDelete = async () => {
    if (propertyToDelete) {
      const auth = getAuth();
      const db = getFirestore();
      const storage = getStorage();

      const realPropertyQuery = query(
        collection(db, "realProperty"),
        where("propertyId", "==", propertyToDelete)
      );
      const realPropertyQuerySnapshot = await getDocs(realPropertyQuery);

      if (!realPropertyQuerySnapshot.empty) {
        realPropertyQuerySnapshot.docs.forEach(async (doc) => {
          const propertyData = doc.data();
          const fileUrl = propertyData.planCopy;
          const file1Url = propertyData.Image1Copy;
          const file2Url = propertyData.Image2Copy;
          const file3Url = propertyData.Image3Copy;

          if (fileUrl) {
            const storageRef = ref(storage, fileUrl);

            deleteObject(storageRef)
              .then(() => {
                console.log("File deleted successfully");
              })
              .catch((error) => {
                console.log(
                  "No file to delete or error deleting file: ",
                  error
                );
              });
          }
          if (file1Url) {
            const storageRef = ref(storage, file1Url);

            deleteObject(storageRef)
              .then(() => {
                console.log("File deleted successfully");
              })
              .catch((error) => {
                console.log(
                  "No file to delete or error deleting file: ",
                  error
                );
              });
          }
          if (file2Url) {
            const storageRef = ref(storage, file2Url);

            deleteObject(storageRef)
              .then(() => {
                console.log("File deleted successfully");
              })
              .catch((error) => {
                console.log(
                  "No file to delete or error deleting file: ",
                  error
                );
              });
          }
          if (file3Url) {
            const storageRef = ref(storage, file3Url);

            deleteObject(storageRef)
              .then(() => {
                console.log("File deleted successfully");
              })
              .catch((error) => {
                console.log(
                  "No file to delete or error deleting file: ",
                  error
                );
              });
          }

          await deleteDoc(doc.ref).catch((error) => {
            console.error("Error deleting realProperty document: ", error);
          });
        });
      }

      setProperties((prevProperties) =>
        prevProperties.filter(
          (property) => property.propertyId !== propertyToDelete
        )
      );

      setIsModalOpen(false);
      setPropertyToDelete(null);

      navigate("/dashboard");
    }
  };

  const BusinessUserNameFunc = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const businessUserDocRef = doc(db, "businessUser", user.uid);
    const businessUserDocSnap = await getDoc(businessUserDocRef);

    if (businessUserDocSnap.exists()) {
      return businessUserDocSnap.data().businessName;
    } else {
      console.log("No such document in businessUser collection!");
      // return "";
    }
  };

  const RealPropertyBusinessIDFunc = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const realPropertyDocRef = doc(db, "realProperty", user.uid);
    const realPropertyDocSnap = await getDoc(realPropertyDocRef);

    if (realPropertyDocSnap.exists()) {
      return realPropertyDocSnap.data().businessId;
    } else {
      console.log("No such document in businessUser collection!");
      // return "";
    }
  };

  const RealPropertyName = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const realPropertyDocRef = doc(db, "realProperty", user.uid);
    const realPropertyDocSnap = await getDoc(realPropertyDocRef);

    if (realPropertyDocSnap.exists()) {
      return realPropertyDocSnap.data().name;
    } else {
      console.log("No such document in realProperty collection!");
      return "";
    }
  };

  //fetch digitalAsset data
  const fetchDigitalAssetData = async (property) => {
    const digitalAssetDocRef = doc(db, "digitalAsset", property.propertyId);
    const digitalAssetDocSnap = await getDoc(digitalAssetDocRef);

    if (digitalAssetDocSnap.exists()) {
      return digitalAssetDocSnap.data();
    } else {
      console.log("No such document in digitalAsset collection!");
      return null;
    }
  };
  //fetching crowdsaleData
  const fetchCrowdSaleData = async (property) => {
    const crowdSaleDocRef = doc(db, "crowdSale", property.propertyId);
    const crowdSaleDocSnap = await getDoc(crowdSaleDocRef);

    if (crowdSaleDocSnap.exists()) {
      return crowdSaleDocSnap.data();
    } else {
      console.log("No such document in crowdSale collection!");
      return null;
    }
  };

  const fetchUserData = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUser(docSnap.data());
    } else {
      console.log("No such document!");
    }

    const businessName = await BusinessUserNameFunc();
    setBusinessName(businessName);

    // Create a query to get the real properties, ordered by `createdAt` in descending order
    const realPropertyQuery = query(
      collection(db, "realProperty"),
      where("businessId", "==", user.uid),
      orderBy("createdAt", "desc") // Order by createdAt in descending order
    );

    const realPropertyQuerySnapshot = await getDocs(realPropertyQuery);

    if (!realPropertyQuerySnapshot.empty) {
      const properties = await Promise.all(
        realPropertyQuerySnapshot.docs.map(async (doc) => {
          const property = doc.data();

          const digitalAssetData = await fetchDigitalAssetData(property);
          const crowdSaleData = await fetchCrowdSaleData(property);
          /* if(!digitalAssetData){
            digitalAssetData=crowdSaleData
          } */
          // if (digitalAssetData) {
          //   setIsAssetCreated((prev) => ({
          //     ...prev,
          //     [property.propertyId]: true,
          //   }));
          // }

          return { ...property, digitalAssetData, crowdSaleData };
        })
      );

      setProperties(properties); // No need to sort again, as the query already handles it
      setPropLoading(false);
      setHasRealProperty(true);
    } else {
      setHasRealProperty(false);
    }
  };

  const fetchNoBlockChainUserData = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUser(docSnap.data());
    } else {
      console.log("No such document!");
    }

    const businessName = await BusinessUserNameFunc();
    setBusinessName(businessName);

    // Create a query to get the real properties, ordered by `createdAt` in descending order
    const realPropertyQuery = query(
      collection(db, "realProperty"),
      where("businessId", "==", user.uid),
      orderBy("createdAt", "desc") // Order by createdAt in descending order
    );

    const realPropertyQuerySnapshot = await getDocs(realPropertyQuery);

    if (!realPropertyQuerySnapshot.empty) {
      const properties = await Promise.all(
        realPropertyQuerySnapshot.docs.map(async (doc) => {
          const property = doc.data();

          const crowdSaleData = await fetchCrowdSaleData(property);

          if (crowdSaleData) {
            setIsSaleCreated((prev) => ({
              ...prev,
              [property.propertyId]: true,
            }));
          }

          return { ...property, crowdSaleData };
        })
      );

      setProperties(properties); // No need to sort again, as the query already handles it
      setPropLoading(false);
      setHasRealProperty(true);
    } else {
      setHasRealProperty(false);
    }
  };

  /*   const fetchUserData = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUser(docSnap.data());
    } else {
      console.log("No such document!");
    }

    const businessName = await BusinessUserNameFunc();
    setBusinessName(businessName);

    const realPropertyQuery = query(
      collection(db, "realProperty"),
      where("businessId", "==", user.uid)
    );
    const realPropertyQuerySnapshot = await getDocs(realPropertyQuery);

    if (!realPropertyQuerySnapshot.empty) {
      const properties = await Promise.all(
        realPropertyQuerySnapshot.docs.map(async (doc) => {
          const property = doc.data();
          const digitalAssetData = await fetchDigitalAssetData(property);
          if (digitalAssetData) {
            setIsAssetCreated((prev) => ({
              ...prev,
              [property.propertyId]: true,
            }));
          }
          return { ...property, digitalAssetData };
        })
      );
     
      setProperties(properties);
      setPropLoading(false);
      setHasRealProperty(true);
    } else {
      setHasRealProperty(false);
    }

  }; */

  useEffect(() => {
    fetchUserData();
  }, [db]);
  useEffect(() => {
    fetchUserData();
  }, []);
  // useEffect(() => {
  //   fetchNoBlockChainUserData();
  // }, [db]);
  useEffect(() => {
    fetchNoBlockChainUserData();
  }, []);

  const handleProfileClick = () => {
    setDropdownOpen(!dropdownOpen);
    if (notificationOpen) {
      setNotificationOpen(false);
    }
  };

  const handleNotificationClick = () => {
    setNotificationOpen(!notificationOpen);
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("User signed out");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const handleDeleteAccount = async () => {
    if (window.confirm("Do you really want to delete your account?")) {
      const auth = getAuth();
      const user = auth.currentUser;
      const userDocRef = doc(db, "users", user.uid);
      const businessUserDocRef = doc(db, "businessUser", user.uid);

      const realPropertyQuery = query(
        collection(db, "realProperty"),
        where("businessId", "==", user.uid)
      );
      const realPropertyQuerySnapshot = await getDocs(realPropertyQuery);

      if (!realPropertyQuerySnapshot.empty) {
        const storage = getStorage();

        realPropertyQuerySnapshot.docs.forEach(async (doc) => {
          const propertyData = doc.data();
          const planCopyUrl = propertyData.planCopy;

          if (planCopyUrl) {
            const storageRef = ref(storage, planCopyUrl);

            deleteObject(storageRef)
              .then(() => {
                console.log("File deleted successfully");
              })
              .catch((error) => {
                console.log(
                  "No file to delete or error deleting file: ",
                  error
                );
              });
          }

          await deleteDoc(doc.ref).catch((error) => {
            console.error("Error deleting realProperty document: ", error);
          });
        });
      }

      await deleteDoc(businessUserDocRef).catch((error) => {
        console.error("Error deleting businessUser document: ", error);
      });

      await deleteDoc(userDocRef).catch((error) => {
        console.error("Error deleting user document: ", error);
      });

      user
        .delete()
        .then(() => {
          console.log("User account deleted");
          navigate("/");
        })
        .catch((error) => {
          console.error("Error deleting user account: ", error);
        });
    }
  };

  //Business Offer using propertyId and user id

  const [selectedOffer, setSelectedOffer] = useState(null);
  const [offers, setOffers] = useState([]);

  // useEffect(() => {
  //   const fetchOffers = async () => {
  //     const auth = getAuth();
  //     const user = auth.currentUser;
  //     const db = getFirestore();

  //     const businessUserRef = collection(db, "businessUser");
  //     const businessUserQuery = query(
  //       businessUserRef,
  //       where("userId", "==", user.uid)
  //     );
  //     const businessUserSnapshot = await getDocs(businessUserQuery);
  //     const businessIds = businessUserSnapshot.docs.map((doc) => doc.id);

  //     const propertiesRef = collection(db, "realProperty");
  //     const properties = [];
  //     for (const businessId of businessIds) {
  //       const propertiesQuery = query(
  //         propertiesRef,
  //         where("businessId", "==", businessId)
  //       );
  //       const propertiesSnapshot = await getDocs(propertiesQuery);

  //       const propertiesData = propertiesSnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //       properties.push(...propertiesData);
  //     }

  //     const propertyIds = properties.map((property) => property.propertyId);

  //     const offers = [];
  //     for (const propertyId of propertyIds) {
  //       const offersRef = collection(db, "customerOffer");
  //       const offersQuery = query(
  //         offersRef,
  //         where("propertyId", "==", propertyId),orderBy("createdAt","desc")
  //       );
  //       const offersSnapshot = await getDocs(offersQuery);

  //       const offersData = offersSnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //       offers.push(...offersData);
  //     }

  //     setOffers(offers);
  //   };

  //   fetchOffers();
  // }, []);

  //Business Offer using propertyId and user id end

  //fetch property names when component mounts or offers change
  useEffect(() => {
    const fetchPropertyNames = async () => {
      const names = {};
      for (const offer of offers) {
        const name = await fetchPropertyName(offer.propertyId);
        names[offer.propertyId] = name;
      }
      setPropertyNames(names);
    };

    fetchPropertyNames();
  }, [offers]);

  const fetchPropertyName = async (propertyId) => {
    const realPropertyDocRef = doc(db, "realProperty", propertyId);
    const realPropertyDocSnap = await getDoc(realPropertyDocRef);

    if (realPropertyDocSnap.exists()) {
      return realPropertyDocSnap.data().name;
    } else {
      console.log(
        `No document with ID ${propertyId} in realProperty collection!`
      );
      return null;
    }
  };

  //get business id

  const fetchBusinessId = async (propertyId) => {
    const realPropertyDocRef = doc(db, "realProperty", propertyId);
    const realPropertyDocSnap = await getDoc(realPropertyDocRef);

    if (realPropertyDocSnap.exists()) {
      return realPropertyDocSnap.data().businessId;
    } else {
      console.log(
        `No document with ID ${propertyId} in realProperty collection!`
      );
      return null;
    }
  };

  useEffect(() => {
    const fetchPropertyData = async () => {
      const names = {};
      const businessIds = {};
      for (const offer of offers) {
        const name = await fetchPropertyName(offer.propertyId);
        const businessId = await fetchBusinessId(offer.propertyId);
        names[offer.propertyId] = name;
        businessIds[offer.propertyId] = businessId;
      }
      setPropertyNames(names);
      setBusinessIds(businessIds);
    };

    fetchPropertyData();
  }, [offers]);

  //fetch offers

  const fetchOffers = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const db = getFirestore();

      const propertiesRef = collection(db, "realProperty");
      const propertiesQuery = query(
        propertiesRef,
        where("businessId", "==", user.uid),
        orderBy("createdAt", "desc")
      );
      const propertiesSnapshot = await getDocs(propertiesQuery);

      const propertyIds = propertiesSnapshot.docs.map((doc) => doc.id);

      const offersPromises = propertyIds.map(async (propertyId) => {
        const offersRef = collection(db, "customerOffer");
        const offersQuery = query(
          offersRef,
          where(
            "propertyId",
            "==",
            propertyId
          ) /* orderBy("createdAt","desc") */
        );
        const offersSnapshot = await getDocs(offersQuery);

        return offersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      });

      const offersArray = await Promise.all(offersPromises);
      const offers = offersArray.flat();

      setOffers(offers);
    } catch (error) {
      console.error("Error fetching offers: ", error);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  //fetch offers end

  //disable offer buttons
  const [clickedOffers, setClickedOffers] = useState(() => {
    const savedState = localStorage.getItem("clickedOffers");
    return savedState ? JSON.parse(savedState) : {};
  });

  useEffect(() => {
    localStorage.setItem("clickedOffers", JSON.stringify(clickedOffers));
  }, [clickedOffers]);

  //disable offer buttons end

  //handleAccept Offer ethers

  const handleAcceptOffer = async (offerId) => {
    // const contractAddress = "0xe392f1198e97843724751BddE8E8a9aC8cF5CD37"; // thirdeyeMarketContract 4 new

    try {
      /* //check metamask installed
      if (typeof window.ethereum === "undefined") {
        alert("MetaMask is not installed!");
        return;
      }

      //initialize ethers.js
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();

      const agreementId = offerId;

      //validate agreementId
      if (!agreementId || typeof agreementId !== "string") {
        throw new Error("Invalid agreementId");
      }

      //ABI of the contract
      const abi = [

        "function depositToken1(string agreementId)"
      ];

      //create a contract instance
      const contract = new ethers.Contract(thirdeye_market_contract, abi, signer);

      //gas Limit
      const gasLimit = ethers.utils.hexlify(250000); //manual gas limit
      // const amountInEther = "0.1"; // Replace with the desired amount
      // const value = ethers.utils.parseEther(amountInEther);
      //encode the function call
      const data = contract.interface.encodeFunctionData("depositToken1", [
        agreementId,
      ]);

      const tx = {
        to: thirdeye_market_contract,
        data: data,

        gasLimit,




      };

     console.log("gas limit: ", gasLimit);
      console.log("data: ", data);
      console.log("account: ", await signer.getAddress());
      console.log("thirdeye market contract address: ", thirdeye_market_contract);
      console.log("offerId: ", agreementId);

      //send transaction
      const transactionResponse = await signer.sendTransaction(tx);
      await transactionResponse.wait(); */

      //update Firestore document
      const offerRef = doc(db, "customerOffer", offerId);
      await updateDoc(offerRef, { status: "ACCEPT" });

      console.log(`Offer ${offerId} has been accepted successfully.`);
      fetchOffers();
      setClickedOffers((prevState) => ({
        ...prevState,
        [offerId]: true,
      }));
    } catch (error) {
      console.error("Error accepting offer: ", error);
      alert(`Error accepting offer: ${error.message || error}`);
    }

    //update Firestore document
    // const offerRef = doc(db, "customerOffer", offerId);
    // await updateDoc(offerRef, { status: "ACCEPT" });

    // console.log(`Offer ${offerId} has been accepted successfully.`);
    // fetchOffers();
    // setClickedOffers((prevState) => ({
    //   ...prevState,
    //   [offerId]: true,
    // }));
  };

  if (!user) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500">
          {" "}
        </div>
      </div>
    );
  }

  return (
    < div style={{height:"100vh"}}>
      <div >
        <Header>
          <Nav>
            <FlexStart>
              <LogoImage src={logo} alt="Thirdeye"  onClick={()=>navigate("/")} />
            </FlexStart>
            <div className="flex items-center lg:order-2 relative">
              <Button ref={notificationRef} onClick={handleNotificationClick}>
                <span className="sr-only">View notifications</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 14 20"
                >
                  <path d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z" />
                </svg>
              </Button>
              <Tooltip
                title="Email to
contact@saralam.tech
for any queries "
              >
                <HelpOutlineIcon></HelpOutlineIcon>
              </Tooltip>

              {notificationOpen && (
                <NotificationDropdown
                  id="notification-dropdown"
                  style={{
                    top: notificationRef.current
                      ? notificationRef.current.offsetHeight
                      : 0,
                  }}
                >
                  <NotificationHeader>No Notifications</NotificationHeader>
                  {/* <NotificationLink href="#">View More</NotificationLink> */}
                </NotificationDropdown>
              )}
              <ProfileButton ref={profileRef} onClick={handleProfileClick}>
                <span className="sr-only">Open user menu</span>
                <ProfileIcon>👤</ProfileIcon>
              </ProfileButton>
              {dropdownOpen && (
                <DropdownMenu
                  id="dropdown"
                  style={{
                    top: profileRef.current
                      ? profileRef.current.offsetHeight
                      : 0,
                  }}
                >
                  <UserDetails>
                    <UserName>{user.name}</UserName>
                    <UserCity>{user.city}</UserCity>
                  </UserDetails>
                  <ul>
                    {/* <li>
                      <MenuItem1 href="#" onClick={()=>setUserProfileOpen(true)}>My profile</MenuItem1>
                      {userProfileOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                      <div className="bg-white p-4 rounded-md shadow-md">
                        <form>
                          
                          <div className="mb-4">
                            <label className="block text-gray-700">
                              Mobile
                            </label>
                            <input
                              type="text"
                              value={user.mobile}
                              readOnly
                              className="w-full p-2 border rounded-md"
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block text-gray-700">Name</label>
                            <input
                              type="text"
                              value={user.name}
                              readOnly
                              className="w-full p-2 border rounded-md"
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block text-gray-700">City</label>
                            <input
                              type="text"
                              value={user.city}
                              readOnly
                              className="w-full p-2 border rounded-md"
                            />
                          </div>
                          <div className="mt-4 flex justify-end">
                           
                            <button
                              onClick={()=>setUserProfileOpen(false)}
                              className="bg-gray-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-gray-600"
                            >
                              Back
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>)
                  }
                    </li> */}

                    <li>
                      <MenuItemLink
                        href="#"
                        onClick={() => setUserProfileOpen(true)}
                      >
                        My profile
                      </MenuItemLink>
                      {userProfileOpen && (
                        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                          <div className="bg-white p-4 rounded-md shadow-md">
                            {isProfileEditing ? (
                              <div>
                                <div className="mb-4">
                                  <label className="block text-gray-700">
                                    Mobile
                                  </label>
                                  <input
                                    type="text"
                                    value={user.mobile}
                                    readOnly
                                    className="w-full p-2 border rounded-md"
                                  />
                                </div>
                                <div className="mb-4">
                                  <label className="block text-gray-700">
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    value={userName}
                                    onChange={(e) =>
                                      setUserName(e.target.value)
                                    }
                                    className="w-full p-2 border rounded-md"
                                  />
                                </div>
                                <div className="mb-4">
                                  <label className="block text-gray-700">
                                    City
                                  </label>
                                  <input
                                    type="text"
                                    value={userCity}
                                    onChange={(e) =>
                                      setUserCity(e.target.value)
                                    }
                                    className="w-full p-2 border rounded-md"
                                  />
                                </div>
                                <div className="mt-4 flex justify-end">
                                  <button
                                    onClick={() => {
                                      setUserProfileOpen(false);
                                      setIsProfileEditing(false);
                                    }}
                                    className="bg-gray-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-gray-600 mr-2"
                                  >
                                    Back
                                  </button>

                                  {!isProfileEditing ? (
                                    <button
                                      onClick={() => setIsProfileEditing(true)}
                                      className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600"
                                    >
                                      Edit profile
                                    </button>
                                  ) : (
                                    <button
                                      onClick={handleprofileSave}
                                      className="bg-green-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600"
                                    >
                                      Save
                                    </button>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="mb-4">
                                  <label className="block text-gray-700">
                                    Mobile
                                  </label>
                                  <input
                                    type="text"
                                    value={user.mobile}
                                    readOnly
                                    className="w-full p-2 border rounded-md"
                                  />
                                </div>
                                <div className="mb-4">
                                  <label className="block text-gray-700">
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    value={userName}
                                    onChange={(e) =>
                                      setUserName(e.target.value)
                                    }
                                    readOnly={!isProfileEditing}
                                    className="w-full p-2 border rounded-md"
                                  />
                                </div>
                                <div className="mb-4">
                                  <label className="block text-gray-700">
                                    City
                                  </label>
                                  <input
                                    type="text"
                                    value={userCity}
                                    onChange={(e) =>
                                      setUserCity(e.target.value)
                                    }
                                    readOnly={!isProfileEditing}
                                    className="w-full p-2 border rounded-md"
                                  />
                                </div>
                                <div className="mt-4 flex justify-end">
                                  <button
                                    onClick={() => setUserProfileOpen(false)}
                                    className="bg-gray-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-gray-600 mr-2"
                                  >
                                    Back
                                  </button>
                                  {!isProfileEditing ? (
                                    <button
                                      onClick={() => setIsProfileEditing(true)}
                                      className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600"
                                    >
                                      Edit profile
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        setShowSaveConfirmation(true)
                                      }
                                      className="bg-green-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600"
                                    >
                                      Save
                                    </button>
                                  )}
                                </div>
                                {showSaveConfirmation && (
                                  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                                    <div className="bg-white p-4 rounded-md shadow-md">
                                      <p className="text-gray-700">
                                        Are you sure you want to save changes?
                                      </p>
                                      <div className="mt-4 flex justify-end">
                                        <button
                                          onClick={() =>
                                            setShowSaveConfirmation(false)
                                          }
                                          className="bg-gray-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-gray-600 mr-2"
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          onClick={handleprofileSave}
                                          className="bg-green-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600"
                                        >
                                          Confirm
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {/* Save Confirmation Modal */}
                    </li>

                    <li>
                      <MenuItemLink to="/rewardcustomerdash">
                        Switch to customer
                      </MenuItemLink>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <MenuItem1 href="#" onClick={handleLogout}>
                        Sign out
                      </MenuItem1>
                    </li>
                    <li>
                      <MenuItemRed href="#" onClick={handleDeleteAccount}>
                        Delete Account
                      </MenuItemRed>
                    </li>
                  </ul>
                </DropdownMenu>
              )}
            </div>
          </Nav>
        </Header>
      </div>

      {/* Sidebar */}
      <div className="flex  pt-16">
        <Sidebar>
          <MenuItem
            selected={selectedSection === "Properties"}
            onClick={() => setSelectedSection("Properties")}
          >
            <FaBars size={30} className="mb-2" />
            <Text>Property</Text>
          </MenuItem>
          <MenuItem
            selected={selectedSection === "Offers"}
            onClick={() => setSelectedSection("Offers")}
          >
            <FaGift size={30} className="mb-2" />
            <Text>Offers</Text>
          </MenuItem>
          <LogoContainer
            onClick={() => window.open("https://thirdeye.app/", "_blank")}
          >
            <Logoim src={favlogo} alt="Thirdeye" />
            <Text>About</Text>
          </LogoContainer>
        </Sidebar>

        {selectedSection === "Properties" &&
          (PropLoading ? (
            <div className="ml-4/5 w-4/5 p-5 rounded-lg my-0 text-center shadow-lg mx-auto mr-0 overflow-hidden h-[640px] overflow-y-auto">
              <p>Loading...</p>
            </div>
          ) : (
            <>
              <div className="ml-4/5 w-4/5 p-5 rounded-lg my-0 text-center shadow-lg mx-auto mr-0 overflow-hidden h-[640px] overflow-y-auto">
                <p className="text-2xl font-bold mb-4"> My Properties </p>
                {properties.length > 0 ? (
                  properties.map((property, index) => (
                    <div
                      key={index}
                      className="h-auto w-auto my-1 border-b border-gray-200"
                    >
                      <PropertyContainer>
                        <PropertyDetails>
                          <PropertyNameWrapper>
                            <PropertyName>{property.name}</PropertyName>
                            {property.verified ? (
                              <Tooltip title="verified property">
                                <Logo1 src={green_verified} alt="verify" />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Not verified property">
                                <Logo1
                                  src={red_not_verified}
                                  alt="not verify"
                                />
                              </Tooltip>
                            )}
                          </PropertyNameWrapper>
                          {/* <PropertyName>
                          {property.name}
                          {property.verified ? (
                            <Tooltip title="  verified property">
                            <Logo1
                            src={green_verified} alt="verify" />
                            
                          </Tooltip>
                           
                          ) : (

                            <Tooltip title=" Not verified property">
                            <Logo1 src={red_not_verified} alt="not verify" />
                            
                          </Tooltip>
                           
                          )}
                        </PropertyName> */}
                          <PropertyBusinessId>
                            {property.propertyId}
                          </PropertyBusinessId>
                        </PropertyDetails>

                        {/* Inside Window */}
                        {detailsOpen === property.propertyId && (
                          <Container>
                            <Grid>
                              <InputGroup>
                                <Label>City</Label>
                                <Input
                                  type="text"
                                  value={isEditing ? editedCity : property.city}
                                  readOnly={!isEditing}
                                  onChange={(e) =>
                                    setEditedCity(e.target.value)
                                  }
                                />
                              </InputGroup>
                              {isEditing ? (
                                <InputGroup>
                                  <Label>Type</Label>
                                  <Select
                                    value={editedPropertyType}
                                    onChange={(e) =>
                                      setEditedPropertyType(e.target.value)
                                    }
                                  >
                                    <option value="">Select...</option>
                                    <option value="Commercial Building">
                                      Commercial Building
                                    </option>
                                    <option value="Agriculture Land">
                                      Agriculture Land
                                    </option>
                                    <option value="Independent House">
                                      Independent House
                                    </option>
                                    <option value="Residential Apartment">
                                      Residential Apartment
                                    </option>
                                  </Select>
                                </InputGroup>
                              ) : (
                                <InputGroup>
                                  <Label>Type</Label>
                                  <Input
                                    type="text"
                                    value={property.propertyType}
                                    readOnly
                                  />
                                </InputGroup>
                              )}
                              <InputGroup>
                                <Label>Total Area</Label>
                                <Input
                                  type="text"
                                  value={
                                    isEditing
                                      ? editedTotalArea
                                      : property.totalArea
                                  }
                                  readOnly={!isEditing}
                                  onChange={(e) =>
                                    setEditedTotalArea(e.target.value)
                                  }
                                />
                              </InputGroup>
                              <InputGroup>
                                <Label>Total Plots</Label>
                                <Input
                                  type="text"
                                  value={
                                    isEditing
                                      ? editedTotalPlots
                                      : property.totalPlots
                                  }
                                  readOnly={!isEditing}
                                  onChange={(e) =>
                                    setEditedTotalPlots(e.target.value)
                                  }
                                />
                              </InputGroup>
                              <InputGroup>
                                <Label>Sold Plots</Label>
                                <Input
                                  type="text"
                                  value={
                                    isEditing
                                      ? editedSoldPlots
                                      : property.soldPlots
                                  }
                                  readOnly={!isEditing}
                                  onChange={(e) =>
                                    setEditedSoldPlots(e.target.value)
                                  }
                                />
                              </InputGroup>
                              <InputGroup>
                                <Label>Digital Asset</Label>
                                <Input
                                  type="text"
                                  value={
                                    property.digitalAsset
                                      ? "Yes"
                                      : property.crowdSale
                                      ? "Yes"
                                      : "No"
                                  }
                                  readOnly
                                />
                              </InputGroup>
                              <InputGroup>
                                <Label>Verified</Label>
                                <Input
                                  type="text"
                                  value={property.verified ? "Yes" : "No"}
                                  readOnly
                                />
                              </InputGroup>
                              <FlexContainer1>
                                <Button1
                                  onClick={
                                    isEditing
                                      ? () => handleSaveClick(property)
                                      : () => handleEditClick(property)
                                  }
                                  isEditing={isEditing}
                                  disabled={
                                    property.crowdSale || property.digitalAsset
                                  }
                                  style={{
                                    opacity:
                                      property.crowdSale ||
                                      property.digitalAsset
                                        ? 0.5
                                        : 1,
                                    pointerEvents:
                                      property.crowdSale ||
                                      property.digitalAsset
                                        ? "none"
                                        : "auto",
                                  }}
                                >
                                  {isEditing ? "Save" : "Edit"}
                                </Button1>

                                {!property.verified && (
                                  <Button1 onClick={() => setFlagVerify(false)}>
                                    {/*  {isEditing ? "Save" : "Edit"} */}
                                    Verify
                                  </Button1>
                                )}
                                {!flagVerify && (
                                  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                                    <div className="bg-white p-4 rounded-md shadow-md">
                                      <p>Do you want to Verify this Property</p>
                                      <div className="mt-4 flex justify-end">
                                        <button
                                          onClick={() => handleVerify(property)}
                                          className="bg-green-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600 mr-2"
                                        >
                                          Yes
                                        </button>
                                        <button
                                          onClick={() => setFlagVerify(true)}
                                          className="bg-gray-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-gray-600"
                                        >
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </FlexContainer1>
                            </Grid>
                          </Container>
                        )}

                        {coinOpen === property.propertyId && (
                          <div
                            className="w-full p-5 rounded-lg text-center shadow-lg"
                            style={{ marginTop: "120px" }}
                          >
                            {property.digitalAsset || property.crowdSale ? (
                              property.digitalAsset ? (
                                <div className="mb-4 border-b border-gray-200 pb-4">
                                  <h2 className="font-bold">Paper Less</h2>

                                  <div className="grid grid-cols-2 gap-4">
                                    <div className="w-full pr-2">
                                      {property.digitalAssetData &&
                                        property.digitalAssetData
                                          .blockchain && (
                                          <>
                                            <h4 className="font-bold">
                                              Blockchain
                                            </h4>
                                            <input
                                              type="text"
                                              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                              value={
                                                property.digitalAssetData
                                                  .blockchain
                                              }
                                              readOnly
                                            />
                                          </>
                                        )}
                                    </div>

                                    {property.digitalAssetData && (
                                      <>
                                        {property.digitalAssetData
                                          .totalTokens && (
                                          <div className="w-full pl-2">
                                            <h4 className="font-bold">
                                              Token Balance
                                            </h4>
                                            <input
                                              type="text"
                                              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                              value={
                                                property.digitalAssetData
                                                  .totalTokens
                                              }
                                              readOnly
                                            />
                                          </div>
                                        )}
                                        {property.digitalAssetData
                                          .tokenName && (
                                          <div className="w-full pr-2">
                                            <h4 className="font-bold">
                                              Token Name
                                            </h4>
                                            <input
                                              type="text"
                                              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                              value={
                                                property.digitalAssetData
                                                  .tokenName
                                              }
                                              readOnly
                                            />
                                          </div>
                                        )}
                                        {property.digitalAssetData
                                          .tokenAddress && (
                                          <div className="w-full pl-2">
                                            <h4 className="font-bold">
                                              Token Address
                                            </h4>
                                            <input
                                              type="text"
                                              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                              value={
                                                property.digitalAssetData
                                                  .tokenAddress
                                              }
                                              readOnly
                                            />
                                          </div>
                                        )}
                                        {property.digitalAssetData
                                          .walletAddress && (
                                          <div className="w-full pl-2">
                                            <h4 className="font-bold">
                                              Wallet Address
                                            </h4>
                                            <input
                                              type="text"
                                              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                              value={
                                                property.digitalAssetData
                                                  .walletAddress
                                              }
                                              readOnly
                                            />
                                          </div>
                                        )}
                                        {property.digitalAssetData
                                          .baseCurrency && (
                                          <div className="w-full pr-2">
                                            <h4 className="font-bold">
                                              Base Currency
                                            </h4>
                                            <input
                                              type="text"
                                              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                              value={
                                                property.digitalAssetData
                                                  .baseCurrency
                                              }
                                              readOnly
                                            />
                                          </div>
                                        )}
                                        {property.digitalAssetData
                                          .basePrice && (
                                          <div className="w-full pl-2">
                                            <h4 className="font-bold">
                                              Base Price
                                            </h4>
                                            <input
                                              type="text"
                                              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                              value={
                                                property.digitalAssetData
                                                  .basePrice
                                              }
                                              readOnly
                                            />
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                //   <div className="mb-4 border-b border-gray-200 pb-4">
                                //     <h2 className="font-bold">Use Paper </h2>
                                //   <div className="grid grid-cols-2 gap-4">
                                //     <div className="w-full pr-2">
                                //       {property.crowdSaleData &&
                                //         property.crowdSaleData
                                //           . baseCurrency && (
                                //           <>
                                //             <h4 className="font-bold">
                                //               Base Currency
                                //             </h4>
                                //             <input
                                //               type="text"
                                //               className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                //               value={
                                //                 property.crowdSaleData
                                //                   .baseCurrency
                                //               }
                                //               readOnly
                                //             />
                                //           </>
                                //         )}
                                //     </div>

                                //     {property.crowdSaleData && (
                                //       <>
                                //         {property.crowdSaleData
                                //           .marketRate && (
                                //           <div className="w-full pl-2">
                                //             <h4 className="font-bold">
                                //              Market Rate
                                //             </h4>
                                //             <input
                                //               type="text"
                                //               className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                //               value={
                                //                 property.crowdSaleData
                                //                   .marketRate
                                //               }
                                //               readOnly
                                //             />
                                //           </div>
                                //         )}
                                //         {property.crowdSaleData
                                //           .minSaleAmount && (
                                //           <div className="w-full pr-2">
                                //             <h4 className="font-bold">
                                //              Target Amount
                                //             </h4>
                                //             <input
                                //               type="text"
                                //               className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                //               value={
                                //                 property.crowdSaleData
                                //                   .minSaleAmount
                                //               }
                                //               readOnly
                                //             />
                                //           </div>
                                //         )}
                                //         {/* {property.crowdSaleData
                                //           .propertyId && (
                                //           <div className="w-full pl-2">
                                //             <h4 className="font-bold">
                                //               propertyId
                                //             </h4>
                                //             <input
                                //               type="text"
                                //               className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                //               value={
                                //                 property.crowdSaleData
                                //                   .propertyId
                                //               }
                                //               readOnly
                                //             />
                                //           </div>
                                //         )} */}
                                //         {property.crowdSaleData
                                //           .saleEnd && (
                                //           <div className="w-full pl-2">
                                //             <h4 className="font-bold">
                                //              Sale End Date
                                //             </h4>
                                //             <input
                                //               type="text"
                                //               className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                //               value={
                                //                 property.crowdSaleData
                                //                   .saleEnd
                                //               }
                                //               readOnly
                                //             />
                                //           </div>
                                //         )}
                                //         {property.crowdSaleData
                                //           .saleStart && (
                                //           <div className="w-full pr-2">
                                //             <h4 className="font-bold">
                                //             Sale Start Date
                                //             </h4>
                                //             <input
                                //               type="text"
                                //               className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                //               value={
                                //                 property.crowdSaleData
                                //                   .saleStart
                                //               }
                                //               readOnly
                                //             />
                                //           </div>
                                //         )}
                                //         {property.crowdSaleData
                                //           .sellingArea && (
                                //           <div className="w-full pl-2">
                                //             <h4 className="font-bold">
                                //             Selling Units
                                //             </h4>
                                //             <input
                                //               type="text"
                                //               className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                //               value={
                                //                 property.crowdSaleData
                                //                   .sellingArea
                                //               }
                                //               readOnly
                                //             />
                                //           </div>
                                //         )}
                                //         {property.crowdSaleData
                                //           .sellingRate && (
                                //           <div className="w-full pl-2">
                                //             <h4 className="font-bold">
                                //             Selling Rate
                                //             </h4>
                                //             <input
                                //               type="text"
                                //               className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                //               value={
                                //                 property.crowdSaleData
                                //                   .sellingRate
                                //               }
                                //               readOnly
                                //             />
                                //           </div>
                                //         )}
                                //         {property.crowdSaleData
                                //           .totalArea && (
                                //           <div className="w-full pl-2">
                                //             <h4 className="font-bold">
                                //             Total Units
                                //             </h4>
                                //             <input
                                //               type="text"
                                //               className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                //               value={
                                //                 property.crowdSaleData
                                //                   .totalArea
                                //               }
                                //               readOnly
                                //             />
                                //           </div>
                                //         )}

                                //       </>
                                //     )}
                                //   </div>
                                // </div>

                                <Container1>
                                  <Grid>
                                    <InputGroup>
                                      <Label>Total units</Label>
                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData?.totalArea
                                        }
                                        readOnly
                                      />
                                    </InputGroup>
                                    <InputGroup>
                                      <Label>Selling units</Label>
                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData?.sellingArea
                                        }
                                        readOnly
                                      />
                                    </InputGroup>
                                    <InputGroup>
                                      <Label>Minimum units</Label>
                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData?.minimumArea
                                        }
                                        readOnly
                                      />
                                    </InputGroup>
                                    <InputGroup>
                                      <Label>No. Fractions</Label>
                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData?.fractions
                                        }
                                        readOnly
                                      />
                                    </InputGroup>

                                    <InputGroup>
                                      <Label>Available units</Label>
                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData?.availableUnits
                                        }
                                        readOnly
                                      />
                                    </InputGroup>

                                    <InputGroup>
                                      <Label>Base Currency</Label>

                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData
                                            ? String(
                                                property.crowdSaleData
                                                  .baseCurrency
                                              )
                                            : ""
                                        }
                                        readOnly
                                      />
                                    </InputGroup>
                                    <InputGroup>
                                      <Label>Market Rate</Label>

                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData
                                            ? String(
                                                property.crowdSaleData
                                                  .marketRate
                                              )
                                            : ""
                                        }
                                        readOnly
                                      />
                                    </InputGroup>

                                    <InputGroup>
                                      <Label>Selling Rate</Label>

                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData
                                            ? Number(
                                                property.crowdSaleData
                                                  .sellingRate
                                              ).toFixed(2)
                                            : ""
                                        }
                                        readOnly
                                        title="added .25% directly to selling rate"
                                      />
                                    </InputGroup>
                                    <InputGroup>
                                      <Label>Sale Start Date</Label>

                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData
                                            ? String(
                                                property.crowdSaleData.saleStart
                                              )
                                            : ""
                                        }
                                        readOnly
                                      />
                                    </InputGroup>
                                    <InputGroup>
                                      <Label>Sell End Date</Label>

                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData
                                            ? String(
                                                property.crowdSaleData.saleEnd
                                              )
                                            : ""
                                        }
                                        readOnly
                                      />
                                    </InputGroup>
                                    <InputGroup>
                                      <Label>Target Amount</Label>

                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData
                                            ? String(
                                                property.crowdSaleData
                                                  .minSaleAmount
                                              )
                                            : ""
                                        }
                                        readOnly
                                      />
                                    </InputGroup>
                                    <InputGroup>
                                      <Label>Current Sale Amount</Label>

                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData
                                            ? Number(
                                                property.crowdSaleData
                                                  .currentSaleAmount
                                              ).toFixed(2)
                                            : ""
                                        }
                                        readOnly
                                      />
                                    </InputGroup>
                                    <InputGroup>
                                      <Label>Status</Label>

                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData
                                            ? String(
                                                property.crowdSaleData.status
                                              )
                                            : ""
                                        }
                                        readOnly
                                      />
                                    </InputGroup>

                                    <InputGroup>
                                      <Label>Max Discount</Label>

                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData
                                            ? `${Number(
                                                property.crowdSaleData
                                                  .maxDiscount
                                              )} %`
                                            : ""
                                        }
                                        readOnly
                                      />
                                    </InputGroup>

                                    <InputGroup>
                                      <Label>Max Discount units</Label>

                                      <Input
                                        type="text"
                                        value={
                                          property.crowdSaleData
                                            ? Number(
                                                property.crowdSaleData
                                                  .maxDiscountUnits
                                              )
                                            : ""
                                        }
                                        readOnly
                                      />
                                    </InputGroup>
                                  </Grid>
                                  {/* <button
                                   className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                   onClick={handleBlockChainBuy}
                                 >
                                   Buy
                                 </button> */}
                                </Container1>
                              )
                            ) : (
                              <>
                                {!isButtonActive && (
                                  <button
                                    className="px-4 py-2 mb-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                    onClick={() =>
                                      toggleButton(property.propertyId)
                                    }
                                  >
                                    Create Digital Asset
                                  </button>
                                )}
                                {isButtonActive && (
                                  <div className="flex items-center justify-center space-x-2">
                                    <input
                                      type="checkbox"
                                      checked={iswalletcategory}
                                      onClick={() => {
                                        setIswalletcategory(true);
                                        setNoBlockChain(false);
                                        handleCreateAsset(property);
                                        setIsCreatingAsset(true);
                                      }}
                                      className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span>Paper Less</span>

                                    <input
                                      type="checkbox"
                                      checked={noBlockChain}
                                      onChange={() =>
                                        handleNoBlockChain(property)
                                      }
                                      className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span>Use Paper</span>
                                  </div>
                                )}
                                {iswalletcategory ? (
                                  <>
                                    {/* {!isCreatingAsset &&
  !isEdited &&
  !isAssetCreated[property.propertyId] &&
   (
    <button
      className="px-4 py-2 mb-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
      onClick={() => {
        handleCreateAsset(property);
        setIsCreatingAsset(true);

      }}
    >
      Create Digital Asset
    </button>
  )} */}
                                    {isCreatingAsset && (
                                      <>
                                        <div className="flex items-center justify-center space-x-2">
                                          <input
                                            type="checkbox"
                                            checked={isWalletConnected}
                                            onChange={handleWalletConnect}
                                            className="form-checkbox h-5 w-5 text-blue-600"
                                          />
                                          <span>Connect Wallet</span>
                                        </div>
                                        {assets.map((asset, index) => (
                                          <div key={index}>
                                            <hr className="my-4" />{" "}
                                            {/* margin*/}
                                            <div className="grid grid-cols-2 gap-4">
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Select Blockchain
                                                </h4>
                                                <select
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={asset.blockchain}
                                                  onChange={(e) =>
                                                    handleSave(
                                                      index,
                                                      e.target.value,
                                                      asset.numTokens,
                                                      asset.tokenName,
                                                      asset.tokenAddress,
                                                      asset.baseCurrency,
                                                      asset.basePrice
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Select...
                                                  </option>
                                                  <option value="Polygon">
                                                    Polygon
                                                  </option>
                                                  <option value="Ethereum">
                                                    Ethereum
                                                  </option>
                                                  <option value="BASE">
                                                    BASE
                                                  </option>
                                                  <option value="Polkadot">
                                                    Polkadot
                                                  </option>
                                                  <option value="Cardano">
                                                    Cardano
                                                  </option>
                                                </select>
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Wallet Address
                                                </h4>
                                                <input
                                                  type="text"
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={walletAddress}
                                                  readOnly={
                                                    !isWalletAddressEditable
                                                  }
                                                  onChange={(e) =>
                                                    handleSave(
                                                      index,
                                                      asset.blockchain,
                                                      asset.numTokens,
                                                      asset.tokenName,
                                                      e.target.value,
                                                      asset.baseCurrency,
                                                      asset.basePrice
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Number of Tokens
                                                </h4>
                                                <input
                                                  type="number"
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={asset.numTokens}
                                                  onChange={(e) => {
                                                    const numTokens = parseInt(
                                                      e.target.value
                                                    );
                                                    if (
                                                      numTokens >
                                                      property.totalArea
                                                    ) {
                                                      alert(
                                                        "Number of Tokens cannot exceed Total Area"
                                                      );
                                                    } else {
                                                      handleSave(
                                                        index,
                                                        asset.blockchain,
                                                        numTokens,
                                                        asset.tokenName,
                                                        asset.walletAddress,
                                                        asset.baseCurrency,
                                                        asset.basePrice
                                                      );
                                                    }
                                                  }}
                                                  max={property.totalArea}
                                                />
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Token Name
                                                </h4>
                                                <input
                                                  type="text"
                                                  value={asset.tokenName}
                                                  onChange={(e) => {
                                                    const tokenName =
                                                      e.target.value.toUpperCase();
                                                    if (tokenName.length > 6) {
                                                      alert(
                                                        "Token Name must be less than 6 characters"
                                                      );
                                                    } else {
                                                      handleSave(
                                                        index,
                                                        asset.blockchain,
                                                        asset.numTokens,
                                                        tokenName,
                                                        asset.walletAddress,
                                                        asset.baseCurrency,
                                                        asset.basePrice
                                                      );
                                                    }
                                                  }}
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                />
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Base Currency
                                                </h4>
                                                <select
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={asset.baseCurrency}
                                                  onChange={(e) =>
                                                    handleSave(
                                                      index,
                                                      asset.blockchain,
                                                      asset.numTokens,
                                                      asset.tokenName,
                                                      asset.walletAddress,
                                                      e.target.value,
                                                      asset.basePrice
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Select...
                                                  </option>
                                                  <option value="USD">
                                                    USD
                                                  </option>
                                                  <option value="INR">
                                                    INR
                                                  </option>
                                                  <option value="ETH">
                                                    ETH
                                                  </option>
                                                </select>
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Base Price
                                                </h4>
                                                <input
                                                  type="number"
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={asset.basePrice}
                                                  onChange={(e) =>
                                                    handleSave(
                                                      index,
                                                      asset.blockchain,
                                                      asset.numTokens,
                                                      asset.tokenName,
                                                      asset.walletAddress,
                                                      asset.baseCurrency,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Token Address
                                                </h4>
                                                <input
                                                  readOnly
                                                  type="text"
                                                  value={asset.tokenAddress}
                                                  onChange={(e) =>
                                                    handleSave(
                                                      index,
                                                      asset.blockchain,
                                                      asset.numTokens,
                                                      asset.tokenName,
                                                      e.target.value,
                                                      asset.baseCurrency,
                                                      asset.basePrice
                                                    )
                                                  }
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                        <div className="flex justify-center space-x-4 mt-4">
                                          <button
                                            className="px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-700"
                                            onClick={() =>
                                              handleSubmit(property)
                                            }
                                          >
                                            Submit
                                          </button>
                                          <button
                                            className="px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                                            onClick={() =>
                                              setIsCreatingAsset(false)
                                            }
                                          >
                                            Back
                                          </button>
                                        </div>
                                      </>
                                    )}{" "}
                                  </>
                                ) : (
                                  <>
                                    {isCreatingSale && (
                                      <>
                                        {sales.map((sale, index) => (
                                          <div key={index}>
                                            <hr className="my-4" />
                                            <div className="grid grid-cols-2 gap-4">
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Total Units
                                                </h4>
                                                <input
                                                  type="text"
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={property.totalArea}
                                                  onChange={(e) =>
                                                    handleBlockChainSave(
                                                      index,
                                                      sale.baseCurrency,
                                                      sale.marketRate,
                                                      sale.minSaleAmount,

                                                      sale.saleEnd,
                                                      sale.saleStart,
                                                      sale.sellingArea,
                                                      sale.sellingRate,
                                                      e.target.value
                                                    )
                                                  }
                                                  readOnly
                                                />
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Selling Units
                                                </h4>
                                                <input
                                                  type="text"
                                                  value={sale.sellingArea}
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  onChange={(e) => {
                                                    const sellingArea = Number(
                                                      e.target.value
                                                    );
                                                    if (
                                                      sellingArea >
                                                      Number(property.totalArea)
                                                    ) {
                                                      alert(
                                                        "should not be greather than total  units "
                                                      );
                                                      return
                                                    } else {
                                                      handleBlockChainSave(
                                                        index,
                                                        sale.baseCurrency,
                                                        sale.marketRate,
                                                        sale.minSaleAmount,
                                                        sale.saleStart,
                                                        sale.saleEnd,
                                                        e.target.value,
                                                        sale.sellingRate,
                                                        sale.totalArea
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>

                                              <div className="mb-4">
  <h4 className="font-bold">Minimum Units</h4>
  <input
    type="text"
    value={sale.minimumArea}
    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    onChange={(e) => {
      const MinimumArea = Number(e.target.value); // Convert to a number

      setLocalStateMinimuArea(MinimumArea);

      if (!Number.isInteger(property.totalArea / MinimumArea)) {
        alert("Fraction must be a whole number.");
        return
      }
      if(MinimumArea >sale.sellingArea){
        alert("Minimum Units can't be greater than Selling Units")
        return
      }

      if (
        MinimumArea > Number(property.totalArea) ||
        MinimumArea > 1000 ||
        MinimumArea < 1
      ) {
        alert(
          "Should be greater than or equal to 1, and less than or equal to 1000 and Total Units."
        );
        return;
      }
      

      handleBlockChainSave(
        index,
        sale.baseCurrency,
        sale.marketRate,
        sale.minSaleAmount,
        sale.saleStart,
        sale.saleEnd,
        sale.sellingArea,
        sale.sellingRate,
        sale.totalArea,
        MinimumArea, // Pass the numeric value
        sale.fractions,
        sale.maxDiscount,
        sale.maxDiscountUnits
      );
    }}
  />
</div>


                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  No. Fractions
                                                </h4>
                                                <input
                                                  type="text"
                                                  value={
                                                    sale.fractions ||
                                                    (Number.isInteger(property.totalArea /
                                                      localStateMinimumArea)?(property.totalArea /
                                                      localStateMinimumArea) :"")
                                                  }
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  onChange={(e) =>
                                                  {

                                                    handleBlockChainSave(
                                                      index,
                                                      e.target.value,
                                                      sale.marketRate,
                                                      sale.minSaleAmount,

                                                      sale.saleStart,
                                                      sale.saleEnd,
                                                      sale.sellingArea,
                                                      sale.sellingRate,
                                                      sale.totalArea,
                                                      sale.minimumArea,
                                                      property.totalArea /
                                                      localStateMinimumArea
                                                    )
                                                  }
                                                }
                                                  
                                                
                                                />
                                              </div>

                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Base Currency
                                                </h4>
                                                <select
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={sale.baseCurrency}
                                                  onChange={(e) =>
                                                    handleBlockChainSave(
                                                      index,
                                                      e.target.value,
                                                      sale.marketRate,
                                                      sale.minSaleAmount,

                                                      sale.saleStart,
                                                      sale.saleEnd,
                                                      sale.sellingArea,
                                                      sale.sellingRate,
                                                      sale.totalArea,
                                                      sale.minimumArea
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Select...
                                                  </option>
                                                  <option value="USD">
                                                    USD
                                                  </option>
                                                  <option value="INR">
                                                    INR
                                                  </option>
                                                  <option value="ETH">
                                                    ETH
                                                  </option>
                                                </select>
                                                {/* <input
                type="text"
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                value={sale.baseCurrency}
                onChange={(e) =>
                  handleBlockChainSave(
                    index,
                    e.target.value,
                    sale.marketRate,
                    sale.minSaleAmount,

                    sale.saleStart,
                    sale.saleEnd,
                    e.target.value,
                    sale.sellingRate,
                    sale.totalArea
                  )
                }
              /> */}
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Market Rate
                                                </h4>
                                                <input
                                                  type="text"
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={sale.marketRate}
                                                  onChange={(e) =>
                                                    handleBlockChainSave(
                                                      index,
                                                      sale.baseCurrency,
                                                      e.target.value,
                                                      sale.minSaleAmount,

                                                      sale.saleStart,
                                                      sale.saleEnd,
                                                      sale.sellingArea,
                                                      sale.sellingRate,
                                                      sale.totalArea,
                                                      sale.minimumArea
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Selling Rate
                                                </h4>
                                                <input
                                                  type="text"
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={sale.sellingRate}
                                                  onChange={(e) => {
                                                    const sellingrate =
                                                      e.target.value;
                                                    const updatedSellingRate =
                                                      Number(sellingrate) *
                                                      1.0025; // Add 0.25% to selling rate

                                                    // Check if the updated selling rate exceeds the market rate
                                                    if (
                                                      updatedSellingRate >
                                                      Number(sale.marketRate)
                                                    ) {
                                                      alert(
                                                        "selling value should  be less than market value "
                                                      );
                                                    } else {
                                                      handleBlockChainSave(
                                                        index,
                                                        sale.baseCurrency,
                                                        sale.marketRate,
                                                        sale.minSaleAmount,

                                                        sale.saleStart,
                                                        sale.saleEnd,
                                                        sale.sellingArea,
                                                        sellingrate,
                                                        sale.totalArea,
                                                        sale.minimumArea
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Sale Start Date
                                                </h4>
                                                <input
                                                  type="date"
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={sale.saleStart}
                                                  onChange={(e) =>
                                                    handleBlockChainSave(
                                                      index,
                                                      sale.baseCurrency,
                                                      sale.marketRate,
                                                      sale.minSaleAmount,

                                                      e.target.value,
                                                      sale.saleEnd,
                                                      sale.sellingArea,
                                                      sale.sellingRate,
                                                      sale.totalArea,
                                                      sale.minimumArea
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Sale End Date
                                                </h4>
                                                <input
                                                  type="date"
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={sale.saleEnd}
                                                  onChange={(e) =>
                                                    handleBlockChainSave(
                                                      index,
                                                      sale.baseCurrency,
                                                      sale.marketRate,
                                                      sale.minSaleAmount,

                                                      sale.saleStart,
                                                      e.target.value,
                                                      sale.sellingArea,
                                                      sale.sellingRate,
                                                      sale.totalArea,
                                                      sale.minimumArea
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Target Amount
                                                </h4>
                                                <input
                                                  type="text"
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={sale.minSaleAmount}
                                                  onChange={(e) => {
                                                    const minsaleAmount =
                                                      Number(e.target.value);
                                                    const saleAmt = Number(
                                                      Number(sale.sellingArea) *
                                                        Number(sale.sellingRate)
                                                    );
                                                    if (
                                                      minsaleAmount > saleAmt
                                                    ) {
                                                      alert(
                                                        "minimum sale amount isgreater than selling area into selling rate"
                                                      );
                                                    } else {
                                                      handleBlockChainSave(
                                                        index,
                                                        sale.baseCurrency,
                                                        sale.marketRate,
                                                        e.target.value,

                                                        sale.saleStart,
                                                        sale.saleEnd,
                                                        sale.sellingArea,
                                                        sale.sellingRate,
                                                        sale.totalArea,
                                                        sale.minimumArea
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Max Discount
                                                </h4>
                                                <input
                                                  type="text"
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={
                                                    sale.maxDiscount !==
                                                      undefined &&
                                                    sale.maxDiscount !== null &&
                                                    sale.maxDiscount !== ""
                                                      ? `${sale.maxDiscount}%`
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    // Remove any existing '%' and get the numeric value
                                                    const value =
                                                      e.target.value.replace(
                                                        /%/g,
                                                        ""
                                                      );

                                                    // Allow clearing the input (empty value) and accepting 0 as a valid value
                                                    if (
                                                      value === "" ||
                                                      value === "0" ||
                                                      (!isNaN(value) &&
                                                        value >= 0)
                                                    ) {
                                                      handleBlockChainSave(
                                                        index,
                                                        sale.baseCurrency,
                                                        sale.marketRate,
                                                        sale.minSaleAmount,
                                                        sale.saleStart,
                                                        sale.saleEnd,
                                                        sale.sellingArea,
                                                        sale.sellingRate,
                                                        sale.totalArea,
                                                        sale.minimumArea,
                                                        sale.fractions,
                                                        value // Send the number or empty string without the % sign
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>

                                              <div className="mb-4">
                                                <h4 className="font-bold">
                                                  Max Discount Units
                                                </h4>
                                                <input
                                                  type="text"
                                                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                  value={sale.maxDiscountUnits}
                                                  onChange={(e) =>
                                                    handleBlockChainSave(
                                                      index,
                                                      sale.baseCurrency,
                                                      sale.marketRate,
                                                      sale.minSaleAmount,

                                                      sale.saleStart,
                                                      sale.saleEnd,
                                                      sale.sellingArea,
                                                      sale.sellingRate,
                                                      sale.totalArea,
                                                      sale.minimumArea,
                                                      sale.fractions,
                                                      sale.maxDiscount,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ))}

                                        <div className="flex justify-center space-x-4 mt-4">
                                          <button
                                            className="px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-700"
                                            onClick={() =>
                                              handleBlockChainSubmit(property)
                                            }
                                          >
                                            Submit
                                          </button>
                                          <button
                                            className="px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                                            onClick={() =>
                                              setIsCreatingSale(false)
                                            }
                                          >
                                            Back
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )}

                        {locationOpen === property.propertyId && (
                          <>
                            <ResponsiveContainer>
                              <ResponsiveIframeWrapper>
                                <ResponsiveIframe
                                  src={smartLayout}
                                  allow="geolocation"
                                  sandbox="allow-same-origin allow-scripts allow-modals"
                                ></ResponsiveIframe>
                              </ResponsiveIframeWrapper>

                              <SectionTitleWrapper>
                                <SectionTitle
                                  onClick={() =>
                                    window.open(smartLayout, "_blank")
                                  }
                                >
                                  Open in new Tab
                                </SectionTitle>

                                <SectionDivider />
                                <SectionTitle>Plan</SectionTitle>
                                <SectionDivider />
                              </SectionTitleWrapper>

                              <SectionTitleWrapper>
                                {property.planCopy ? (
                                  <PlanLink
                                    onClick={() =>
                                      window.open(property.planCopy, "_blank")
                                    }
                                  >
                                    click here
                                  </PlanLink>
                                ) : (
                                  <NoPlanText>No Plan Copy</NoPlanText>
                                )}
                              </SectionTitleWrapper>

                              <SectionTitleWrapper>
                                <SectionDivider />
                                <SectionTitle>Images</SectionTitle>
                                <SectionDivider />
                              </SectionTitleWrapper>

                              <ImageWrapper>
                                {property.Image1Copy ||
                                property.Image2Copy ||
                                property.Image3Copy ? (
                                  <>
                                    {property.Image1Copy && (
                                      <ImageContainer
                                        isExpanded={
                                          expandedImage === property.Image1Copy
                                        }
                                      >
                                        <img
                                          src={property.Image1Copy}
                                          alt="Image 1"
                                          onClick={() =>
                                            handleImageClick(
                                              property.Image1Copy
                                            )
                                          }
                                        />
                                      </ImageContainer>
                                    )}
                                    {property.Image2Copy && (
                                      <ImageContainer
                                        isExpanded={
                                          expandedImage === property.Image2Copy
                                        }
                                      >
                                        <img
                                          src={property.Image2Copy}
                                          alt="Image 2"
                                          onClick={() =>
                                            handleImageClick(
                                              property.Image2Copy
                                            )
                                          }
                                        />
                                      </ImageContainer>
                                    )}
                                    {property.Image3Copy && (
                                      <ImageContainer
                                        isExpanded={
                                          expandedImage === property.Image3Copy
                                        }
                                      >
                                        <img
                                          src={property.Image3Copy}
                                          alt="Image 3"
                                          onClick={() =>
                                            handleImageClick(
                                              property.Image3Copy
                                            )
                                          }
                                        />
                                      </ImageContainer>
                                    )}
                                  </>
                                ) : (
                                  <NoImageText>No images</NoImageText>
                                )}
                              </ImageWrapper>

                              {expandedImage && (
                                <ExpandedImageOverlay>
                                  <ExpandedImageContainer
                                    onClick={() => setExpandedImage(null)}
                                    onWheel={handleWheel}
                                  >
                                    <ExpandedImage
                                      src={expandedImage}
                                      alt="Expanded"
                                      zoomLevel={zoomLevel}
                                    />
                                    <CloseButton
                                      onClick={() => setExpandedImage(null)}
                                    >
                                      X
                                    </CloseButton>
                                  </ExpandedImageContainer>
                                </ExpandedImageOverlay>
                              )}
                            </ResponsiveContainer>
                          </>
                        )}
                        {/* Inside Window end */}

                        <div className="flex space-x-4">
                          <div className="flex justify-around space-x-10">
                            <div className="property-container">
                              <div className="icon-row flex">
                                <div className="icon-row flex justify-end">
                                  <IconButton
                                    src={icodetails}
                                    alt="Details"
                                    className={`rounded-md w-15 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 active:scale-90 shadow hover:shadow-lg mr-4 ${
                                      pressedDetailsId ===
                                        property.propertyId &&
                                      detailsOpen === property.propertyId
                                        ? "opacity-50"
                                        : ""
                                    }`}
                                    onClick={
                                      isEditing
                                        ? null
                                        : () => {
                                            toggleDetails(property.propertyId);
                                            setPressedDetailsId(
                                              pressedDetailsId !==
                                                property.propertyId
                                                ? property.propertyId
                                                : null
                                            );
                                          }
                                    }
                                  />
                                  <IconButton
                                    src={icocoin}
                                    alt="Coin"
                                    className={`rounded-md w-15 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 active:scale-90 shadow hover:shadow-lg mr-4 ${
                                      pressedCoinId === property.propertyId &&
                                      coinOpen === property.propertyId
                                        ? "opacity-50"
                                        : ""
                                    }`}
                                    onClick={
                                      isEditing
                                        ? null
                                        : () => {
                                            toggleCoin(property.propertyId);
                                            setPressedCoinId(
                                              pressedCoinId !==
                                                property.propertyId
                                                ? property.propertyId
                                                : null
                                            );
                                          }
                                    }
                                  />
                                  <IconButton
                                    src={icolocation}
                                    alt="Location"
                                    className={`rounded-md w-15 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 active:scale-90 shadow hover:shadow-lg mr-4 ${
                                      pressedLocationId ===
                                        property.propertyId &&
                                      locationOpen === property.propertyId
                                        ? "opacity-50"
                                        : ""
                                    }`}
                                    onClick={
                                      isEditing
                                        ? null
                                        : () => {
                                            toggleLocation(property.propertyId);
                                            setPressedLocationId(
                                              pressedLocationId !==
                                                property.propertyId
                                                ? property.propertyId
                                                : null
                                            );
                                          }
                                    }
                                  />
                                  {/* {!property.digitalAsset ? <IconButton
                                    src={icobin}
                                    alt="Bin"
                                    className={`rounded-md w-15 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 active:scale-90 shadow hover:shadow-lg`}
                                    onClick={
                                      isEditing
                                        ? null
                                        : () => {
                                            handleDeleteRealProperty(
                                              property.propertyId
                                            );
                                            setPressedBinId(
                                              pressedBinId !==
                                                property.propertyId
                                                ? property.propertyId
                                                : null
                                            );
                                          }
                                    }
                                  /> :<IconButton
                                  src={icobin}
                                  alt="Bin"
                                  className={` opacity-60 rounded-md w-15 h-12 `}
                                  
                                />  }{" "} */}
                                  <IconButton
                                    src={icobin}
                                    alt="Bin"
                                    className={`rounded-md w-15 h-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 active:scale-90 shadow hover:shadow-lg mr-4 ${
                                      property.digitalAsset ||
                                      property.crowdSale
                                        ? "opacity-60 cursor-not-allowed"
                                        : ""
                                    }`}
                                    style={{
                                      pointerEvents:
                                        property.digitalAsset ||
                                        property.crowdSale
                                          ? "none"
                                          : "auto",
                                    }}
                                    onClick={
                                      isEditing ||
                                      property.digitalAsset ||
                                      property.crowdSale
                                        ? null
                                        : () => {
                                            handleDeleteRealProperty(
                                              property.propertyId
                                            );
                                            setPressedBinId(
                                              pressedBinId !==
                                                property.propertyId
                                                ? property.propertyId
                                                : null
                                            );
                                          }
                                    }
                                  />

                                  {/* 2020 */}
                                </div>
                              </div>
                            </div>
                          </div>
                          {isModalOpen && (
                            <Modal>
                              <ModalContent>
                                <p>
                                  Do you really want to delete this property?
                                </p>
                                <ModalButtons>
                                  <ModalButton primary onClick={confirmDelete}>
                                    Yes
                                  </ModalButton>
                                  <ModalButton
                                    onClick={() => setIsModalOpen(false)}
                                  >
                                    No
                                  </ModalButton>
                                </ModalButtons>
                              </ModalContent>
                            </Modal>
                          )}
                        </div>
                      </PropertyContainer>
                    </div>
                  ))
                ) : (
                  <div className="my-4">
                    <p>No properties to show </p>
                  </div>
                )}
                <div className="my-20">
                  <Link
                    to="/addproperty"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-custom-purple hover:bg-custom-blue"
                  >
                    Add New
                  </Link>
                </div>
              </div>
            </>
          ))}

        {/* Offers */}
        {selectedSection === "Offers" && (
          <div className="ml-4/5 w-4/5 p-5 rounded-lg text-center my-0 shadow-lg mx-auto mr-0">
            <p className="text-lg font-semibold text-gray-900 dark:text-white">
              {offers.length > 0 ? (
                offers
                  // .filter((offer) => offer.status === "ACTIVE")
                  .filter((offer) => offer.status !== "CANCEL")
                  .map((offer, index) => {
                    const propertyName = propertyNames[offer.propertyId];
                    const businessId = businessIds[offer.propertyId];

                    return (
                      <div
                        key={index}
                        className="h-auto w-auto my-1 border-b border-gray-200"
                      >
                        <OfferDetailsContainer>
                          <OfferDetailsColumn>
                            <PropertyName1>{propertyName}</PropertyName1>
                            <BusinessIdUnique>{offer.id}</BusinessIdUnique>
                          </OfferDetailsColumn>
                          <OfferIconRow>
                            <IconButton
                              src={icodetails}
                              alt="Details"
                              onClick={() =>
                                setDetailsOpen(
                                  detailsOpen !== offer.id ? offer.id : null
                                )
                              }
                            />
                          </OfferIconRow>
                        </OfferDetailsContainer>

                        {detailsOpen === offer.id && (
                          <ContainerUnique>
                            <FieldContainerUnique>
                              <LabelUnique>Property</LabelUnique>
                              <InputUnique
                                type="text"
                                value={propertyName}
                                readOnly
                              />
                            </FieldContainerUnique>
                            <FieldContainerUnique>
                              <LabelUnique>Offer Date</LabelUnique>
                              <InputUnique
                                type="text"
                                value={offer.createdAt}
                                readOnly
                              />
                            </FieldContainerUnique>
                            <FieldContainerUnique>
                              <LabelUnique>Offer Expiry</LabelUnique>
                              <InputUnique
                                type="text"
                                value={offer.expiresAt}
                                readOnly
                              />
                            </FieldContainerUnique>
                            <FieldContainerUnique>
                              <LabelUnique>Offer Price</LabelUnique>
                              <InputUnique
                                type="text"
                                value={offer.offerPrice}
                                readOnly
                              />
                            </FieldContainerUnique>
                            <FieldContainerUnique>
                              <LabelUnique>Fractional Value</LabelUnique>
                              <InputUnique
                                type="text"
                                value={offer.fractionalValue}
                                readOnly
                              />
                            </FieldContainerUnique>
                            <FieldContainerUnique>
                              <LabelUnique>Status</LabelUnique>
                              <InputUnique
                                type="text"
                                value={offer.status}
                                readOnly
                              />
                            </FieldContainerUnique>

                            <ButtonContainerUnique>
                              {!clickedOffers[offer.id] &&
                                offer.status !== "COMPLETE" && (
                                  <>
                                    <AcceptButtonUnique
                                      onClick={() =>
                                        handleAcceptOffer(offer.id)
                                      }
                                    >
                                      Accept
                                    </AcceptButtonUnique>
                                    <RejectButtonUnique
                                      onClick={async () => {
                                        try {
                                          const offerRef = doc(
                                            db,
                                            "customerOffer",
                                            offer.id
                                          );
                                          await updateDoc(offerRef, {
                                            status: "REJECT",
                                          });
                                          console.log(
                                            `Offer ${offer.id} has been rejected successfully.`
                                          );
                                          fetchOffers();
                                          setClickedOffers((prevState) => ({
                                            ...prevState,
                                            [offer.id]: true,
                                          }));
                                        } catch (error) {
                                          console.error(
                                            "Error updating document: ",
                                            error
                                          );
                                        }
                                      }}
                                    >
                                      Reject
                                    </RejectButtonUnique>
                                  </>
                                )}
                            </ButtonContainerUnique>
                          </ContainerUnique>
                        )}
                      </div>
                    );
                  })
              ) : (
                <div className="my-4">
                  <p> No offers to display </p>
                </div>
              )}
            </p>
          </div>
        )}
        {/* Offers end */}
        {isPopupVisible && (
          <SuccessPopup onClose={() => setIsPopupVisible(false)} />
        )}


      </div>
      
    </div>
  );
};

export default Dashboard;
