import React, { useState, useEffect } from "react";
import {
  TextField,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import "typeface-inter";

import logo from "../images/thirdeye.png";
import styled from "styled-components";

const db = getFirestore();

const Wrapper = styled.div`
  font-family: sans-serif;
  background-color: #f7fafc; /* bg-gray-100 */
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 150px;
`;

const Header = styled.header`
  background-color: #fff; /* bg-white */
  border-bottom: 1px solid #e2e8f0; /* border-gray-200 */
  padding: 0.625rem 1rem; /* px-4 py-2.5 */
  @media (min-width: 1024px) {
    padding: 0.625rem 1.5rem; /* lg:px-6 */
  }
  background-color: #ffffff; /* dark:bg-gray-800 */
  width: 100%;
  box-sizing: border-box;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Logo = styled.img`
  height: 2.75rem; /* h-11 */
  width: auto;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  background-color: #f7fafc; /* bg-gray-100 */
  padding: 1rem;
  box-sizing: border-box;
`;

const RegisterHeader = styled.div`
  width: 80%;
  max-width: 20rem; /* Limit max-width */
  background-color: #3b82f6; /* bg-custom-blue */
  padding: 1.25rem; /* p-5 */
  border-radius: 0.5rem; /* rounded-lg */
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
`;

const FormContainer = styled.div`
  width: 80%;
  max-width: 20rem; /* Limit max-width */
  background-color: #fff;
  padding: 1.25rem; /* p-5 */
  border-radius: 0.5rem; /* rounded-lg */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* shadow-lg */
  margin-top: 1.25rem; /* mt-5 */
  transition: all 0.5s ease-in-out; /* transition duration-500 ease-in-out */
  transform: translateY(0);
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem; /* p-2 */
  border: 2px solid #d1d5db; /* border-2 border-gray-300 */
  border-radius: 0.375rem; /* rounded-md */
  margin-top: ${({ marginTop }) => marginTop || "0"};
`;

const Button1 = styled.button`
  width: 100%;
  margin-top: 1.25rem; /* mt-5 */
  padding: 0.5rem; /* p-2 */
  background-color: #3b82f6; /* bg-custom-blue */
  color: #fff;
  border-radius: 0.375rem; /* rounded-md */
`;
const AuthSuccess = ({setAlertFormOpen,setauthNewUser,setRewardAuthNewUser, setRewardAlertFormOpen,rewardFormOpen}) => {
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState("");
  const [city, setCity] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.log("No user signed in");
        return;
      }

      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUser(docSnap.data());
        setUsername(docSnap.data().name);
        setCity(docSnap.data().city);
      } else {
        console.log("No such document!");
        await setDoc(docRef, {
          name: "",
          city: "",
          id: user.uid,
          isNewUser: true, // true only when creating a new user
          createdAt: new Date().toISOString(),
          expireAt: null,
          fcmTokens: null,
          membershipId: null,
          mobile: user.phoneNumber,
        });
        setUser({ name: "", city: "", id: user.uid });
      }
    };

    fetchUserData();
  }, []);

  const handleSave = async (event) => {
    event.preventDefault();

    const user = auth.currentUser;
    if (!user) {
      console.log("No user signed in");
      return;
    }

    const docRef = doc(db, "users", user.uid);

    const docSnap = await getDoc(docRef);
    let isNewUser = true;
    if (docSnap.exists() && docSnap.data().isNewUser !== undefined) {
      // get the current value of isNewUser
      isNewUser = docSnap.data().isNewUser;
    }

    await setDoc(
      docRef,
      {
        name: username,
        city: city,
        isNewUser: isNewUser, // current db value or true if undefined
        createdAt: new Date().toISOString(),
        expireAt: null,
        fcmTokens: null,
        id: user.uid,
        membershipId: null,
        mobile: user.phoneNumber,
      },
      { merge: true }
    ); 
    /* navigate("/area"); */
    if(!rewardFormOpen){
      setAlertFormOpen(true)
    setauthNewUser(false)
    }
    if(rewardFormOpen){
      setRewardAlertFormOpen(true)
      setRewardAuthNewUser(false)
    }
    
  

   

  };

  return (
    <Wrapper>
      {/* <Header>
        <HeaderContent>
          <LogoContainer>
            <Logo src={logo} alt="Thirdeye"/>
          </LogoContainer>
        </HeaderContent>
      </Header> */}
      <MainContainer>
        <RegisterHeader>
          <h2>Register</h2>
        </RegisterHeader>
        <FormContainer>
          <form onSubmit={handleSave}>
            <Input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
            <Input
              type="text"
              placeholder="City"
              value={city}
              onChange={(event) => setCity(event.target.value)}
              marginTop="1.25rem" /* mt-5 */
            />
            <Button1 type="submit">SAVE</Button1>
          </form>
        </FormContainer>
      </MainContainer>
    </Wrapper>
  );
};

export default AuthSuccess;
