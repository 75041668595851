import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCRZ2o631TgKB1PXO22TF5RnG1OGJdigCs",
  authDomain: "realblock-e5db7.firebaseapp.com",
  databaseURL: "https://realblock-e5db7.firebaseio.com",
  projectId: "realblock-e5db7",
  storageBucket: "realblock-e5db7.appspot.com",
  messagingSenderId: "85438278754",
  appId: "1:85438278754:web:2af1eafed7d4b0aecd753b",
  measurementId: "G-LLKN6XQG4M"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// const auth = getAuth(app);

export const auth = getAuth(app);