import styled from 'styled-components';
 
export const FAQContainer = styled.div`
  max-width: 800px;
  top:60px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
 overflow-y:auto;
 position:relative;
  @media (max-width: 768px) {
    padding: 15px;
  }
 
  @media (max-width: 480px) {
    padding: 10px;
  }
`;
 
export const Heading = styled.h1`
 
  font-size: 1.5rem;
  color: #007bff;
  text-align: center;
  margin-bottom: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
  background:black;
//   background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,121,94,1) 35%, rgba(0,212,255,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeIn 1.5s ease-in-out;
 
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
 
  @media (max-width: 480px) {
    font-size: 1.6rem;
  }
 
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
 
export const QuestionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 15px 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
 
  &:hover {
    background-color: #f0f0f0;
    color: #007bff; /* Changes text color on hover */
  }
 
  @media (max-width: 768px) {
    padding: 12px 15px;
  }
 
  @media (max-width: 480px) {
    padding: 10px 12px;
  }
`;
 
export const Question = styled.h2`
  font-size: 1.2rem;
  color: #333;
  margin: 0;
  transition: color 0.3s ease;
 
  ${QuestionWrapper}:hover & {
    color: #007bff;
  }
 
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
 
  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;
 
export const Icon = styled.div`
  font-size: 1.2rem;
  color: #555;
  transition: transform 0.3s ease, color 0.3s ease;
 
  ${QuestionWrapper}:hover & {
    color: #007bff;
    transform: scale(1.1);
  }
`;
 
export const Answer = styled.p`
  font-size: 1rem;
  color: #555;
  margin: 0;
  padding: 10px 20px;
  background-color: #fff;
  border-left: 4px solid #2196f3;
  border-radius: 0 0 5px 5px;
  transition: max-height 0.4s ease, opacity 0.4s ease;
  overflow: hidden;
  max-height: 200px;
  opacity: 1;
 
  @media (max-width: 768px) {
    font-size: 0.95rem;
    padding: 8px 15px;
  }
 
  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 7px 12px;
  }
`;


export const Header = styled.header`
  background-color: #fff; /* bg-white */
  border-bottom: 1px solid #e2e8f0; /* border-gray-200 */
  padding: 0.625rem 1rem; /* px-4 py-2.5 */
  position: fixed; /* Make the header fixed */
  top: 0;
  width: 100%; /* Ensure it spans the full width */
  z-index: 50; /* Ensure it's on top of other elements */
  
  @media (min-width: 1024px) {
    padding: 0.625rem 1.5rem; /* lg:px-6 */
  }

  &.dark {
    background-color: #2d3748; /* dark:bg-gray-800 */
  }
`;


export const HeaderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.img`
  height: 2.75rem; /* h-11 */
  width: auto;
  align-self: center;
`;

export const  Faqmain = styled.div`

display:flex;
flex-direction:column;
position:relative;

`;