import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8fafc; /* Light background */
  text-align: center;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  @media (max-width: 576px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 4rem;
  color: #2d3748; /* Dark text */
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 576px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  color: #4a5568; /* Muted text */
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 576px) {
    font-size: 0.875rem;
  }
`;

const GoHomeButton = styled.button`
  background-color: #3182ce; /* Blue background */
  color: #fff;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2b6cb0; /* Darker blue on hover */
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }

  @media (max-width: 576px) {
    font-size: 0.75rem;
    padding: 0.5rem;
  }
`;

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <NotFoundContainer>
      <Title>404</Title>
      <Description>Oops! The page you are looking for does not exist.</Description>
      <GoHomeButton onClick={() => navigate("/")}>Go Back to Home</GoHomeButton>
    </NotFoundContainer>
  );
};

export default NotFound;
