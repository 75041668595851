import React, { useState } from "react";
import logo from "../images/thirdeye.png";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import howitwork from "../images/howitwork.png"

export const Container = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7fafc; /* bg-gray-100 */
  position: fixed;
  width: 100%;
  height: 100%;
  // overflow: hidden; /* Disable scrolling */
`;

export const Header = styled.header`
  background-color: #ffffff; /* bg-white */
  border-bottom: 1px solid #e2e8f0; /* border-gray-200 */
  padding: 0.625rem 1rem; /* px-4 py-2.5 */
  width: 100%;
  positon:fixed;
  @media (min-width: 1024px) {
    padding: 0.625rem 1.5rem; /* lg:px-6 */
  }
  &.dark {
    background-color: #2d3748; /* dark:bg-gray-800 */
  }
`;

export const HeaderInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.img`
  height: 2.75rem; /* h-11 */
  width: auto;
  align-self: center;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 4rem); /* Adjust based on header height */
  width: 100%;
  background-color: #f7fafc; /* bg-gray-100 */
  padding-bottom:150px;
`;

const BlueBox = styled.div`
  width: 80%; /* w-4/5 */
  max-width: 400px; /* Limit max-width */
  background-color: #3b82f6; /* bg-custom-blue */
  padding: 1.25rem; /* p-5 */
  border-radius: 0.5rem; /* rounded-lg */
  color: #ffffff;
  text-align: center;
  @media (max-width: 768px) {
    width: 90%; /* Adjust width for smaller screens */
  }
  @media (max-width: 576px) {
    width: 95%; /* Adjust width for even smaller screens */
  }
`;

const WhiteBox = styled.div`
  width: 80%; /* w-4/5 */
  max-width: 400px; /* Limit max-width */
  background-color: #ffffff; /* bg-white */
  padding: 1.25rem; /* p-5 */
  border-radius: 0.5rem; /* rounded-lg */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* shadow-lg */
  margin-top: 1.25rem; /* mt-5 */
  transition: transform 0.5s ease-in-out; /* transition duration-500 ease-in-out */
  @media (max-width: 768px) {
    width: 90%; /* Adjust width for smaller screens */
  }
  @media (max-width: 576px) {
    width: 95%; /* Adjust width for even smaller screens */
  }
`;

const Form = styled.form`
  width: 100%;
  margin-top: 1.25rem; /* mt-5 */
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-top: 0.5rem; /* mt-2 */
`;

const Input = styled.input`
   margin-right: 0.5rem;
  margin-top: 0.1rem;
`;

const Button = styled.button`
  width: 100%;
  margin-top: 1.25rem; /* mt-5 */
  padding: 0.5rem; /* p-2 */
  background-color: #3b82f6; /* bg-custom-blue */
  color: #ffffff;
  border-radius: 0.375rem; /* rounded-md */
`;

const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const imageStyles = {
  maxWidth: '90%',
  maxHeight: '90%',
  border: '2px solid white',
  boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
  cursor: 'pointer',
};

const AlertComponent = ({setAlertFormOpen,setauthNewUser,setRewardAuthNewUser, setRewardAlertFormOpen,rewardFormOpen,setRewardFormOpen,setAuthFormOpen}) => {
  const [loginType, setLoginType] = useState("");
  //const [expandedImage, setExpandedImage] = useState(null);
  const navigate = useNavigate();
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
 

  return (
    
      

      <MainContent>
        <BlueBox>
          <h2 className="font-inter">Congrats!! you've earned LAND</h2>
        </BlueBox>
        <WhiteBox>
          
            <div className="w-full mt-5">
              <Label className="text-blue-500 underline font-bold cursor-pointer hover:text-blue-700 hover:scale-105 transition-transform mb-2" onClick={()=>{
                if(rewardFormOpen){
                    localStorage.setItem("rewardFormOpen",rewardFormOpen)
                    navigate("/rewardcustomerdash")
                    setRewardAlertFormOpen(false)
                    setRewardAuthNewUser(false)

                }
                else{
                  localStorage.setItem("rewardFormOpen",rewardFormOpen)
                    navigate("/rewardcustomerdash")
                    setAlertFormOpen(false)
                    setauthNewUser(false)
                }
                
                }}>
              Go to Dashboard
              </Label>
              <Label className="text-gray-600 font-medium cursor-pointer hover:text-gray-800 hover:scale-105 transition-transform mt-2" onClick={()=>{
                
                    setAlertFormOpen(false)
                    setauthNewUser(false)
                    setRewardFormOpen(false)
                    setAuthFormOpen(false)
                
                
              }}>
              Stay on this page
              </Label>
            </div> 

        </WhiteBox>
      </MainContent>
   
  );
};

export default AlertComponent;