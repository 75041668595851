import { useParams } from "react-router-dom";
import OakBrookGarden from "./OakBrookGarden";
import TirumalaHills2 from "./TirumalaHills2";
import NotFound from "./NotFound";

export const DynamicRoute = () => {
  const { sanitizedProjectName } = useParams();
  const decodedTitle = sanitizedProjectName.replace(/-/g, ' ');

  switch (decodedTitle) {
    case "Oak Brook Gardens":
      return <OakBrookGarden/>;
    case "Tirumala Hills 2":
      return <TirumalaHills2/>;
    default:
      return <NotFound/>; 
  }
};



